import React, { useEffect, useState, useCallback, memo } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ProductShortcut from "./Productshortcut";
import { Spinner, Pagination } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Abook = memo(() => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = useCallback(async (page) => {
    try {
      const response = await fetch("/api/posts/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          null_image: false,
          image_per_page: parseInt(25),
          page_number: page,
          type: "کتاب صوتی",
          only_in_stock: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const { posts, count } = await response.json();
      setdata(posts);
      setTotalPages(count % 30 === 0 ? count / 30 : Math.floor(count / 30) + 1);
    } catch (error) {
      errornotif("خطای برقراری ارتباط ، لطفا بعدا تلاش کنید");
    }
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [fetchData, page]);

  useEffect(() => {
    setLoading(data.length === 0);
  }, [data]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const errornotif = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  return (
    <div className="Abook">
      <ToastContainer />
      <Navbar />
      {loading ? (
        <div className="w-full h-[100vh] flex items-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="app flex justify-center flex-wrap  pt-[120px] pb-[50px]">
            {data.map((product) => (
              <div
                key={product.name}
                className="zero sealprodcon pt-10 !mx-auto inline-block"
              >
                <div className="w-[97%] seallprod h-full ">
                  <ProductShortcut
                    image={product.image}
                    discountPrice={product.discounted_price}
                    name={product.name}
                    writer={product.author}
                    price={product.price}
                    type={product.type}
                    alt={product.name}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex  my-10 justify-center">
            <Pagination
              className="bg-gray-400 p-5 rounded-2xl"
              total={totalPages}
              initialPage={page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
});

export default Abook;
