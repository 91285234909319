import React, { useRef, useEffect, useState } from "react";

import Deletepost from "./Deletepost";
import { Chip } from "@nextui-org/react";
import { Spinner } from "@nextui-org/react";
import Editstock from "./Editstock";

const Subacord = (props) => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const element = useRef(null);

  const temphidden = () => {
    element.current.classList.add("none");
    props.func();
  };
  useEffect(() => {
    const fetchProduct = async () => {
      const response = await fetch("/api/posts/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          null_image: true,
          image_per_page: 1,
          page_number: 1,
          tag: props.tag,
          only_out_of_stock: props.only_out_of_stock,
          only_discounted: props.only_discounted,
        }),
      });

      const result = await response.json();
      setProduct(result.posts);
      setLoading(false);
    };
    fetchProduct();
  }, []);
  return (
    <div className="h-[500px] scrolld  overflow-scroll pb-10  ">
      <div>
        {loading ? (
          <div className="w-full mt-[230px] h-auto flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          product.map((item, index) => (
            <div
              key={index}
              ref={element}
              dir="rtl"
              className="w-full relative flex justify-between items-center rounded-md bg-[#e6e6e8] px-4 py-3 my-1"
            >
              <div className="zero">{item.name}</div>
              <div className="flex zero items-center">
                <Chip className="absolute left-[140px] pt-1" color="primary">
                  {item.slider_tag}
                </Chip>
                <Editstock
                  fnc={props.fnc}
                  err={props.err}
                  succ={props.succ}
                  stock={item.in_stock}
                  price={item.price}
                  discounted={item.discounted_price}
                  name={item.name}
                />
                <Deletepost
                  func={temphidden}
                  err={props.err}
                  succ={props.succ}
                  name={item.name}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Subacord;
