import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Accordion, AccordionItem } from "@nextui-org/react";
import Subacord from "./Subacord";

const API_SLIDER_LIST = "/api/slider/list";

const Acordians = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const response = await fetch(API_SLIDER_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const result = await response.json();
      setData(result);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      function: fetchData,
    };
  });

  return (
    <div className="w-[100%] rounded-xl mt-7 ">
      {isLoading && (
        <Accordion variant="splitted">
          {data.map((content) => (
            <AccordionItem
              key={content.tag}
              className="zero [&>*:first-child]:w-full overflow-visible [&>*:first-child]:[&>*:first-child]:[&>*:first-child]:[&>*:first-child]:m-0 justify-between"
              aria-label={content.name}
              title={content.name}
            >
              {
                <Subacord
                  succ={props.succ}
                  err={props.err}
                  func={props.func}
                  tag={content.tag}
                  price
                  only_discounted={false}
                  only_out_of_stock={false}
                />
              }
            </AccordionItem>
          ))}
          <AccordionItem
            className="zero [&>*:first-child]:w-full overflow-visible [&>*:first-child]:[&>*:first-child]:[&>*:first-child]:[&>*:first-child]:m-0 justify-between"
            aria-labe
            title={"فروش ویژه"}
          >
            {
              <Subacord
                succ={props.succ}
                err={props.err}
                func={props.func}
                only_discounted={true}
                only_out_of_stock={false}
                tag={null}
              />
            }
          </AccordionItem>
          <AccordionItem
            className="zero [&>*:first-child]:w-full overflow-visible [&>*:first-child]:[&>*:first-child]:[&>*:first-child]:[&>*:first-child]:m-0 justify-between"
            aria-labe
            title={"محصولات به اتمام رسیده"}
          >
            {
              <Subacord
                succ={props.succ}
                err={props.err}
                func={props.func}
                only_out_of_stock={true}
                only_discounted={false}
                tag={null}
              />
            }
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
});

export default Acordians;
