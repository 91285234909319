import React, { useEffect, useState, useRef } from "react";
import { FaRegUser } from "react-icons/fa";
import { LuBookMinus } from "react-icons/lu";
import { BiBasket } from "react-icons/bi";
import { TfiLayoutSlider } from "react-icons/tfi";
import Crslider from "./Crslider";
import Productl from "./Productl";
import Crproduct from "./Crproduct";
import { Spinner } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sliderl from "./Sliderl";
import Acordians from "./Acordians";
import { useNavigate } from "react-router";
import { Button } from "@nextui-org/react";
import { HiOutlineHome } from "react-icons/hi";
import AdminOrders from "./AdminOrders";

const Admin = () => {
  const [Orders, setOrders] = useState();
  const [Orderload, setOrderload] = useState();
  const [slidercount, setslidercount] = useState();
  const [productcount, setproductcount] = useState();
  const [usercount, setusercount] = useState();
  const [sliderloading, setsliderloading] = useState(true);
  const [productloading, setproductloading] = useState(true);
  const [userloading, setuserloading] = useState(true);
  const [isAd, setAd] = useState(false);
  const [checking, setChecking] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const checkAdmin = async () => {
      setChecking(true);
      const response = await fetch("/api/admin/is_admin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        }),
      });
      if (response.ok) {
        setAd((await response.text()) === "true");
      }
      setChecking(false);
    };
    checkAdmin();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [sliderdata, setsliderdata] = useState([]);
  const ttst = useRef(null);
  const fdata = useRef(null);

  const errornotif = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  async function fetchData() {
    setIsLoading(true);
    const response = await fetch("/api/posts/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        null_image: true,
        image_per_page: 1,
        page_number: 1,
      }),
    });
    if (response.ok) {
      const result = await response.json();
      setdata(result.posts);
    }
    setIsLoading(false);
  }

  async function sliderfetchData() {
    setIsLoading(true);

    const response = await fetch("/api/slider/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const result = await response.json();
      setsliderdata(await result);
    }
    setIsLoading(false);
  }

  const fetchOrder = async () => {
    setOrderload(true);
    const response = await fetch("/api/admin/stats/count_order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });
    if (response.ok) {
      const result = await response.text();
      setOrders(result);
      setOrderload(false);
    }
  };
  const fetchcount = async () => {
    setproductloading(true);
    const response = await fetch("/api/admin/stats/count_post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });
    if (response.ok) {
      const result = await response.text();
      setproductcount(result);
      setproductloading(false);
    }
  };

  const fetchslidercount = async () => {
    setsliderloading(true);
    const response = await fetch("/api/admin/stats/count_slider", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });
    if (response.ok) {
      const result = await response.text();
      setslidercount(result);
      setsliderloading(false);
    }
  };

  const fetchusercount = async () => {
    setuserloading(true);
    const response = await fetch("/api/admin/stats/count_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });
    if (response.ok) {
      const result = await response.text();
      setusercount(result);
      setuserloading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
    fetchcount();
    fetchslidercount();
    fetchusercount();
    fetchData();
    sliderfetchData();
  }, []);

  const successnotif = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  const rell = () => {
    ttst.current.function();
    fdata.current.function();
  };

  return (
    <>
      <Button
        onClick={() => navigate("/")}
        isIconOnly
        className={
          "fixed rounded-full index w-[70px] h-[70px] bg-[#6673DD] bottom-6 right-5"
        }
      >
        <HiOutlineHome className="text-3xl text-white" />
      </Button>
      {checking ? (
        <div className="w-full flex">
          <Spinner color="primary" />
        </div>
      ) : isAd ? (
        <div className="app flex-col flex">
          <ToastContainer />
          <div className="w-full pt-4  ">
            <div className="flex flex-wrap w-full ">
              <div
                dir="ltr"
                className="w-[24%] headinfo items-center justify-between flex rounded-xl p-4 bg-white shadow-lg "
              >
                <BiBasket className="text-5xl text-[#6673DD] zero" />
                <div className="zero">
                  <h3 className="flex flex-col text-lg">
                    تعداد فروش ها
                    <span className="mr-0 mt-4 text-3xl">
                      {Orderload ? (
                        <Spinner />
                      ) : (
                        parseInt(Orders)?.toLocaleString("fa")
                      )}
                    </span>
                  </h3>
                </div>
              </div>
              <div
                dir="ltr"
                className="w-[24%] headinfo items-center justify-between flex rounded-xl p-4 bg-white shadow-lg 
                    "
              >
                <FaRegUser className="text-5xl text-[#6673DD] zero" />
                <div className="zero">
                  <h3 className="flex flex-col text-lg">
                    حساب ها
                    <span className="mr-0 mt-4 text-3xl">
                      {userloading ? (
                        <Spinner />
                      ) : (
                        parseInt(usercount)?.toLocaleString("fa")
                      )}
                    </span>
                  </h3>
                </div>
              </div>
              <div
                dir="ltr"
                className="w-[24%] headinfo items-center justify-between flex rounded-xl p-4 bg-white shadow-lg "
              >
                <LuBookMinus className="text-5xl text-[#6673DD] zero" />
                <div className="zero">
                  <h3 className="flex flex-col text-lg">
                    تعداد محصولات
                    <span className="mr-0 mt-4 text-3xl">
                      {productloading ? (
                        <Spinner />
                      ) : (
                        parseInt(productcount)?.toLocaleString("fa")
                      )}
                    </span>
                  </h3>
                </div>
              </div>
              <div
                dir="ltr"
                className="w-[24%] headinfo items-center justify-between flex rounded-xl p-4 bg-white shadow-lg "
              >
                <TfiLayoutSlider className="text-5xl text-[#6673DD] zero" />
                <div className="zero">
                  <h3 className="flex flex-col text-lg">
                    تعداد اسلایدر ها
                    <span className="mr-0 mt-4 text-3xl">
                      {sliderloading ? (
                        <Spinner />
                      ) : (
                        parseInt(slidercount)?.toLocaleString("fa")
                      )}
                    </span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="w-[99%] ccn justify-between p mt-7 flex ">
              <div className="smt w-[65%] prodc p-3 flex flex-col h-[700px] zero bg-white shadow-xl rounded-xl  ">
                <Crproduct
                  ref={ttst}
                  fnc={rell}
                  succ={successnotif}
                  func={fetchData}
                />
                {isLoading ? (
                  <div className="w-full h-full flex justify-center items-center">
                    <Spinner className="m-auto" />
                  </div>
                ) : (
                  <div dir="ltr" className="overflow-auto  scrolld zero">
                    {data &&
                      data.map((content) => (
                        <Productl
                          fnc={fetchData}
                          stock={content.in_stock}
                          discounted={content.discounted_price}
                          price={content.price}
                          key={content.name}
                          tag={content.slider_tag}
                          name={content.name}
                          err={errornotif}
                          succ={successnotif}
                        />
                      ))}
                  </div>
                )}
              </div>
              <div className="w-[34%]   h-[700px] zero flex justify-between flf flex-col ">
                <div className="bg-white slf p-3 flex flex-col shadow-xl h-full w-full zero rounded-xl ">
                  <Crslider
                    fnc={rell}
                    func={sliderfetchData}
                    succ={successnotif}
                  />
                  <div dir="ltr" className="overflow-auto  scrolld zero">
                    {isLoading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Spinner className="m-auto" />
                      </div>
                    ) : (
                      <div dir="ltr" className="overflow-auto  scrolld zero">
                        {sliderdata &&
                          sliderdata.map((content) => (
                            <Sliderl
                              err={errornotif}
                              fnc={rell}
                              key={content.name}
                              tag={content.tag}
                              name={content.name}
                              succ={successnotif}
                            />
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Acordians
            ref={fdata}
            func={fetchData}
            err={errornotif}
            succ={successnotif}
          />
          <AdminOrders succ={successnotif} />
        </div>
      ) : (
        navigate("/notfound")
      )}
    </>
  );
};
export default Admin;
