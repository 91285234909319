import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@nextui-org/react";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    server: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({ ...errors, server: "" });

    const newErrors = {};
    if (!formData.username) {
      newErrors.username = "نام کاربری نمی‌تواند خالی باشد";
    } else if (formData.username.length < 6) {
      newErrors.username = "نام کاربری باید حداقل 6 کاراکتر داشته باشد";
    }
    if (!formData.password) {
      newErrors.password = "رمز عبور نمی‌تواند خالی باشد";
    } else if (formData.password.length < 6) {
      newErrors.password = "رمز عبور باید حداقل 6 کاراکتر داشته باشد";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    const response = await fetch("/api/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: formData.username,
        password: formData.password,
      }),
    });
    if (response.ok) {
      successnotif("ورود با موفقیت انجام شد");
      localStorage.setItem("token", await response.text());
      localStorage.setItem("username", formData.username);
      const isadmin = await fetch("/api/admin/is_admin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        }),
      });
      if ((await isadmin.text()) === "true") {
        navigate("/admin");
      } else {
        navigate("/");
      }
    } else if (response.status === 401 || response.status === 404) {
      errnotif("نام کاربری یا رمز عبور اشتباه است");
    } else {
      errnotif("مشکلی پیش آمد  بعدا تلاش کنید");
    }

    setIsLoading(false);
  };

  const successnotif = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };
  const errnotif = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-[40px] py-[50px] rounded-[30px] shadow-md w-96">
        <h2 className="text-2xl font-semibold flex justify-center mb-[80px]">
          ورود
        </h2>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              htmlFor="username"
              className="block mr-2 mb-2 text-sm font-medium text-gray-600"
            >
              نام کاربری:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className={`mt-1 p-2 bg-[#EDEDEE] pr-3 w-full rounded-[20px] focus:outline-none ${
                errors.username ? "border border-red-500" : ""
              }`}
            />
            {errors.username && (
              <div className="text-red-500">{errors.username}</div>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="password"
              className="block mb-2 mr-2 text-sm font-medium text-gray-600"
            >
              رمزعبور:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`mt-1 p-2 pr-3 bg-[#EDEDEE] w-full rounded-[20px] focus:outline-none ${
                errors.password ? "border border-red-500" : ""
              }`}
            />
            {errors.password && (
              <div className="text-red-500">{errors.password}</div>
            )}
          </div>
          <div className="mb-10 mr-2 text-[#3aa3b6]">
            <Link to={"/resetpassword"}>رمزعبور را فراموش کرده اید؟</Link>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600 focus:outline-none"
            disabled={isLoading}
          >
            {isLoading ? <Spinner color="default" size="sm" /> : "ورود"}
          </button>
        </form>
        {errors.server && (
          <div className="text-red-500 mt-3">{errors.server}</div>
        )}
        <div className="flex mt-3 mr-1 text-blue-500 w-full ">
          <Link to={"/register"}>ثبت نام</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
