import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./Components/Register.jsx";
import App from "./App.jsx";
import Login from "./Components/Login";
import Admin from "./Components/Admin/Admin.jsx";
import Abook from "./Components/Abook.jsx";
import Seeall from "./Components/Seeall.jsx";
import Book from "./Components/Book.jsx";
import About from "./Components/About.jsx";
import Checkout from "./Components/Checkout.jsx";
import Ebook from "./Components/Ebook.jsx";
import Notfound from "./Components/Notfound.jsx";
import Orders from "./Components/Orders.jsx";
import OrderSuccess from "./Components/OrderSuccess.jsx";
import OrderFailed from "./Components/OrderFailed.jsx";
import Product from "./Components/Product.jsx";
import Resetpass from "./Components/Resetpass.jsx";
import RequestPhoneNumber from "./Components/RequestPhoneNumber.jsx";
import Shopingcart from "./Components/Shopingcart.jsx";
import Seealldiscount from "./Components/Sealldiscount.jsx";
import Searchres from "./Components/Searchres.jsx";

import "./index.css";

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <Routes>
      <Route path="/products/discounted" element={<Seealldiscount />} />
      <Route path="/" element={<App />} />
      <Route path="/resetpassword" element={<RequestPhoneNumber />} />
      <Route path="/resetpassword/:id" element={<Resetpass />} />
      <Route path="/cart" element={<Shopingcart />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/products/:id" element={<Seeall />} />
      <Route path="/About" element={<About />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/search/:id" element={<Searchres />} />
      <Route path="/ebook" element={<Ebook />} />
      <Route path="/abook" element={<Abook />} />
      <Route path="/book" element={<Book />} />
      <Route path="/failed" element={<OrderFailed />} />
      <Route path="/success" element={<OrderSuccess />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  </BrowserRouter>
);
