import React, { useState } from "react";
import {
  NextUIProvider,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from "@nextui-org/react";
import { FiPlusSquare } from "react-icons/fi";
import { Spinner } from "@nextui-org/react";

const Crslider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [servererr, setservererr] = useState();
  const [formData, setFormData] = useState({
    sliderName: "",
    sliderTag: "",
  });
  const [errors, setErrors] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.sliderName) {
      newErrors.sliderName = "نام اسلایدر نمی‌تواند خالی باشد";
    }
    if (!formData.sliderTag) {
      newErrors.sliderTag = "تگ اسلایدر نمی‌تواند خالی باشد";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setIsLoading(true);

    const stringdata = JSON.stringify({
      login_data: {
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      },
      name: formData.sliderName.trim(),
      tag: formData.sliderTag.trim(),
    });

    const response = await fetch("/api/admin/slider/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: stringdata,
    });

    if (response.status >= 200 && response.status <= 299) {
      props.func();
      props.succ("محصول با موفقیت اضافه شد");
      props.fnc();
      closehandler();
    } else if (response.status == 409) {
      setservererr("اسلایدری با این تگ قبلا اضافه شده");
    } else {
      setservererr("مشکلی پیش آمد لطفا بعدا تلاش کنید");
    }

    setIsLoading(false);
  };

  const closehandler = () => {
    setservererr(null);
    setErrors({});
    onClose();
  };

  return (
    <div className="zero">
      <NextUIProvider>
        <div className="w-full mb-7 px-3 flex items justify-between pt-3">
          <h3 className="text-lg flex items-center">اسلایدر ها</h3>
          <Button
            onPress={onOpen}
            className="px-3 py-2 rounded-lg bg-[#8b97f4] flex items-center text-white"
          >
            اضافه کردن <FiPlusSquare className="text-white mr-1" />
          </Button>
          <Modal
            backdrop="opaque"
            isOpen={isOpen}
            onClose={closehandler}
            classNames={{
              backdrop:
                "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
            }}
          >
            <ModalContent>
              <form onSubmit={handleSubmit}>
                <ModalBody className="zero inline-block">
                  <div className="flex">
                    <div className="mt-5 w-[48%]">
                      <Input
                        size="sm"
                        type="text"
                        label="نام اسلایدر"
                        name="sliderName"
                        value={formData.sliderName}
                        onChange={handleChange}
                      />
                      {errors.sliderName && (
                        <p className="text-red-500 mt-1 text-sm">
                          {errors.sliderName}
                        </p>
                      )}
                    </div>
                    <div className="mt-5 w-[48%]">
                      <Input
                        size="sm"
                        type="text"
                        label="تگ اسلایدر"
                        name="sliderTag"
                        value={formData.sliderTag}
                        onChange={handleChange}
                      />
                      {errors.sliderTag && (
                        <p className="text-red-500 mt-1 text-sm">
                          {errors.sliderTag}
                        </p>
                      )}
                    </div>
                  </div>
                  {servererr && (
                    <p className="text-red-500 mt-1 text-sm pt-1 pr-1">
                      {servererr}
                    </p>
                  )}
                </ModalBody>
                <ModalFooter className="pt-1 pb-2">
                  <Button color="danger" variant="light" onClick={closehandler}>
                    بستن
                  </Button>
                  <Button color="primary" type="submit">
                    {isLoading ? (
                      <Spinner size="sm" color="default" />
                    ) : (
                      <span>اضافه کردن</span>
                    )}
                  </Button>
                  {errors.general && (
                    <p className="text-red-500 mt-1 text-sm">
                      {errors.general}
                    </p>
                  )}
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        </div>
      </NextUIProvider>
    </div>
  );
};

export default Crslider;
