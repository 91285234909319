import React, { useEffect, useState, useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaArrowLeft } from "react-icons/fa";
import Productshortcut from "./Productshortcut";
import { Link } from "react-router-dom";
import { Skeleton } from "@nextui-org/react";

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1499 }, items: 5 },
  llaptop: { breakpoint: { max: 1499, min: 1199 }, items: 4 },
  laptop: { breakpoint: { max: 1199, min: 991 }, items: 3 },
  tablet: { breakpoint: { max: 991, min: 576 }, items: 2 },
  dmobile: { breakpoint: { max: 768, min: 480 }, items: 2 },
  mobile: { breakpoint: { max: 480, min: 0 }, items: 1 },
};

const Slider = (props) => {
  const [data, setdata] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/posts/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_per_page: parseInt(15),
          page_number: parseInt(1),
          tag: props.tag,
          only_in_stock: true,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setdata(result.posts);
        setLoading(false);
      }
    };
    fetchData();
  }, [props.tag]);

  const products = useMemo(
    () =>
      data?.map((product) => (
        <div
          key={product.id}
          className="s-box border-2 overflow-hidden border-[#bebebe] rounded-3xl mx-3"
        >
          <Productshortcut
            image={product.image}
            discountPrice={product.discounted_price}
            name={product.name}
            writer={product.author}
            price={product.price}
            type={product.type}
            alt={`Product ${product.name}`}
          />
        </div>
      )),
    [data]
  );
  String.prototype.replaceSpacesWithPlus = function () {
    return this.replace(/ /g, "+");
  };
  return (
    <div
      className={`w-full ${
        data?.length === 0 ? "" : ""
      } bg-white rounded-xl my-10 pb-10 pt-1 shadow-lg`}
    >
      <div className="app">
        <h3 className="text-[25px] t items-center flex justify-between mr-8 my-6">
          <span className="ttr zero">{props.name}</span>
          <Link
            to={`/products/${props.tag.replaceSpacesWithPlus()}`}
            className="sall zero flex items-center pl-10 text-[#1A799E] text-[18px]"
          >
            مشاهده همه <FaArrowLeft className="text-[12px] mt-[3px]" />
          </Link>
        </h3>
        <div className="w-full">
          <Carousel rtl={true} className={"slid"} responsive={responsive}>
            {isLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <div
                    key={index}
                    className="s-box border-2 overflow-hidden  border-[#bebebe] rounded-3xl mx-3"
                  >
                    <Skeleton className="rounded-lg">
                      <div className="h-[350px] rounded-lg bg-secondary"></div>
                    </Skeleton>
                    <div className="space-y-3 flex flex-col justify-end my-4">
                      <Skeleton className="mr-2 w-3/5 rounded-lg">
                        <div className="h-3 w-full rounded-lg  bg-secondary"></div>
                      </Skeleton>
                      <Skeleton className="mr-2 w-4/5 rounded-lg">
                        <div className="h-3 w-full rounded-lg  bg-secondary-300"></div>
                      </Skeleton>
                      <Skeleton className="mr-2 w-2/5 rounded-lg">
                        <div className="h-3 w-full rounded-lg  bg-secondary-200"></div>
                      </Skeleton>
                    </div>
                  </div>
                ))
              : products}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Slider;
