import React from "react";
import { IoLocationOutline, IoCall } from "react-icons/io5";
import { GoDot } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { BsFeather } from "react-icons/bs";
import { FaInstagram} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-full bg-[#e7eaed] py-8 ">
      <div className="app h-full flex w-full brd">
        <div className="fw w-[30%] zero flx">
          <h3 className="flex items-center text-[22px]">
            <IoLocationOutline
              className="zero mt-1 text-[#078791] rounded-full text-[35px] bg-[#C3CBCD] ml-3 p-2"
              aria-label="Location"
            />
            <span className="cn text-[#404C57] zero pr-3">آدرس فروشگاه</span>
          </h3>
          <p className="mt-[20px] flex  mr-1">
            <GoDot
              className="zero text-[#078791] text-[20px]"
              aria-label="Dot"
            />
            <span className="zero px-3">
              استان گیلان، بندرانزلی،میدان امام خمینی،روبروی کافه کینو،انتشارات
              سپیدقلم
            </span>
          </p>
          <div className="hcon">
            <h3 className="flex mt-[30px] items-center text-[22px]">
              <IoCall
                className="zero mt-1 text-[#078791] rounded-full text-[35px] bg-[#C3CBCD] ml-3 p-2"
                aria-label="Phone"
              />
              <span className="text-[#404C57] zero pr-3">۰۹۱۱۳۳۰۴۰۷۹</span>
            </h3>
            <h3 className="hhh flex mt-[30px] items-center text-[22px]">
              <MdEmail
                className="zero mt-1 text-[#078791] rounded-full text-[35px] bg-[#C3CBCD] ml-3 p-2"
                aria-label="Email"
              />
              <span className="text-[#404C57] text-[18px] pt-1 zero pr-3">
                sepidghalam1398@gmail.com
              </span>
            </h3>
          </div>
        </div>
        <div className="sec-con fw w-[70%] h-full mr-10">
          <h3 className="flex items-center justify-between text-[22px]">
            <div className="flex items-center zero">
              <BsFeather
                className="zero mt-1 text-[#078791] rounded-full text-[35px] bg-[#C3CBCD] ml-3 p-2"
                aria-label="Feather"
              />
              <span className="text-[#404C57] zero pr-3">سپید قلم</span>
            </div>
            <div className="zero"></div>
          </h3>
          <p className="mt-[20px] flex relative mr-1">
            <GoDot
              className="gd zero text-[#078791] right-3 top-1 absolute text-[1rem]"
              aria-label="Dot"
            />
            <span className="fboat zero text-[1.1rem] px-10">
              از فروردین سال ۱۳۹۸، انتشارات سپید قلم به عنوان یک ناشر پویا و
              پیشرو به ارتقاء فرهنگ کتابخوانی و منتشر کردن آثار مفید و با کیفیت
              در دسته‌های گوناگون متمرکز کرده است. تیم متخصص و متعهد ما تاکنون
              به انتشار آثاری پرداخته که توانسته‌اند افکار را گسترش دهند و
              ذهن‌ها را به چالش بکشند. این انتشارات با تمرکز بر کیفیت، تنوع، و
              ارتقاء سلیقه خوانندگان، به عنوان پلی دوستانه بین نویسندگان و
              خوانندگان عمل کرده و به محیطی پویا افتخار می‌کند. برآنیم که
              نویسندگان با دید نوین و محتوای جذاب را شناسایی کرده و آثارشان را
              منتشر کنیم. این سفر همراه با انتشارات سپید قلم، به شما جهانی از
              دانش، هنر، و تجربه‌های جذاب را ارائه می‌دهد. با بیش از ۳۰۰ اثر
              منتشر شده تحت مدیریت دکتر منصوره دل آور، ما همواره در کنار شما
              خواهیم بود.
            </span>
          </p>
        </div>
      </div>
      <div className="bfooter mt-2 app py-2 w-full flex justify-between ">
        <div className="text-[#404C57] bfooter-text zero">
          © کلیه حقوق این سایت محفوظ و متعلق به سپید قلم است
        </div>
        <div className="zero flex ficon">
          <a href="https://www.instagram.com/seppid_ghalam/" target="_blank">
            <FaInstagram
              className="ml-2 insta  inline-block text-[#078791]  text-[1.7rem]"
              aria-label="Instagram"
            />
          </a>
          <a
            referrerPolicy="origin"
            target="_blank"
            href="https://trustseal.enamad.ir/?id=458880&Code=nlZdLzTsX3CrTlDGQ5cFXdNLgKxB1Vin"
          >
            <img
              referrerPolicy="origin"
              src="https://trustseal.enamad.ir/logo.aspx?id=458880&Code=nlZdLzTsX3CrTlDGQ5cFXdNLgKxB1Vin"
              alt="نماد اعتماد الکترونیک"
              className="cursor-pointer w-[30px] h-[30px]"
              id="nlZdLzTsX3CrTlDGQ5cFXdNLgKxB1Vin"
              code="nlZdLzTsX3CrTlDGQ5cFXdNLgKxB1Vin"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
