import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
} from "@nextui-org/react";
import { Input } from "@nextui-org/react";

const Register = () => {
  const navigate = useNavigate();
  const convertToEnglishDigits = (input) => {
    const persianDigits = [
      /۰/g,
      /۱/g,
      /۲/g,
      /۳/g,
      /۴/g,
      /۵/g,
      /۶/g,
      /۷/g,
      /۸/g,
      /۹/g,
    ];
    let result = input;

    persianDigits.forEach((digit, index) => {
      result = result.replace(digit, index);
    });

    return result;
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [lad, setlad] = useState(false);
  const [code, setcode] = useState();

  const [formval, setFormval] = useState({
    username: "",
    password: "",
    phone_number: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    phone_number: "",
    server: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const convertedValue =
      name === "phone_number" ? convertToEnglishDigits(value) : value;

    setFormval((prev) => ({ ...prev, [name]: convertedValue }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const convertedPhoneNumber = convertToEnglishDigits(formval.phone_number);

    setFormval((prev) => ({ ...prev, phone_number: convertedPhoneNumber }));

    setErrors({ ...errors, server: "" });

    const validationErrors = validate(formval);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    const serverUrl = "/api/users/register";
    const response = await fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formval),
    });

    if (response.ok) {
      setcode(await response.text());
      onOpen();
    } else if (response.status == 409) {
      errnotif("این شماره یا نام کاربری قبلا استفاده شده است");
    } else if (response.status == 429) {
      errnotif("بعد از ۲ دقیقه دوباره تلاش کنید");
    } else {
      errnotif("مشکلی پیش آمد  بعدا تلاش کنید");
    }
    setIsLoading(false);
  };

  const validate = (values) => {
    const errors = {};
    const iranianPhoneRegex = /^09\d{9}$/;

    if (!values.username) {
      errors.username = "نام کاربری اجباری است!";
    } else if (!(values.username.length > 5)) {
      errors.username = "نام کاربری باید حداقل 6 کاراکتر داشته باشد.";
    }

    if (!values.password) {
      errors.password = "رمزعبور اجباری است!";
    } else if (!isStrongPassword(values.password)) {
      errors.password = "رمزعبور باید حداقل 6 کاراکتر داشته باشد.";
    }

    if (!values.phone_number) {
      errors.phone_number = "شماره تلفن اجباری است!";
    } else if (!iranianPhoneRegex.test(values.phone_number)) {
      errors.phone_number = "شماره تلفن معتبر نیست!";
    }

    return errors;
  };

  const isStrongPassword = (password) => {
    const weakPasswordRegex = /^.{6,}$/;
    return weakPasswordRegex.test(password);
  };
  const successnotif = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };
  const errnotif = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  const codeval = useRef(null);
  const [secer, setSecer] = useState("");
  const allowedDigits = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "۰",
    "۱",
    "۲",
    "۳",
    "۴",
    "۵",
    "۶",
    "۷",
    "۸",
    "۹",
  ];

  const isValidInput = (inputValue) => {
    for (let char of inputValue) {
      if (!allowedDigits.includes(char)) {
        return true;
      }
    }
    return false;
  };

  const changehandler = () => {
    setSecer("");
  };

  function persianToEnglishNumbers(input) {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    const englishDigits = "0123456789";
    let output = "";
    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const index = persianDigits.indexOf(char);
      if (index >= 0) {
        output += englishDigits[index];
      } else {
        output += char;
      }
    }
    return output;
  }

  const handlecode = async (e) => {
    e.preventDefault();

    const inputValue = codeval.current.value;

    if (isValidInput(inputValue)) {
      setSecer("کد وارد شده معتبر نیست");
    } else if (inputValue === "") {
      setSecer("کد نباید خالی باشد");
    } else if ([...inputValue].length != 6) {
      setSecer("کد باید حاوی ۶ رقم باشم");
    } else {
      setlad(true);
      const response = await fetch("/api/users/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          verification_token: code,
          verification_code: parseInt(persianToEnglishNumbers(inputValue)),
        }),
      });

      if (response.ok) {
        handkemodalclose();
        successnotif("ثبت نام با موفقیت انجام شد");
        setIsLoading(true);
        const respons = await fetch("/api/users/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: formval.username,
            password: formval.password,
          }),
        });
        if (respons.ok) {
          localStorage.setItem("token", await respons.text());
          localStorage.setItem("username", formval.username);
          navigate("/");
        } else {
          errnotif("مشکلی پیش آمد بعدا تلاش کنید");
        }
        setIsLoading(false);
      } else {
        setSecer("کد وارد شده صحیح نیست");
      }
      setlad(false);
    }
  };
  const handkemodalclose = () => {
    setSecer("");
    onClose();
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-[40px] py-[50px] rounded-[30px] shadow-md w-96">
        <div>
          <Modal
            backdrop="opaque"
            isOpen={isOpen}
            placement={"center"}
            classNames={{
              backdrop:
                "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
            }}
          >
            <ModalContent className="w-auto dldldl">
              <form onSubmit={handlecode}>
                <ModalBody className="zero  inline-block">
                  <h3 className="mt-3 mb-6">
                    کدی که به شماره شما ارسال شده را وارد کنید
                  </h3>
                  <Input
                    onChange={changehandler}
                    ref={codeval}
                    color="primary"
                    type="text"
                    className=":firs w-full"
                    variant={"underlined"}
                    placeholder="کد را وارد کنید"
                  />

                  <p className="mt-5 mr-10 text-red-500">{secer}</p>
                </ModalBody>
                <ModalFooter className="pt-1 w-full flex  pb-2 ">
                  <Button color="danger" variant="light" onPress={onClose}>
                    بستن
                  </Button>
                  <Button color="primary" type="submit">
                    {lad ? (
                      <Spinner size="sm" color="default" />
                    ) : (
                      <span>ثبت</span>
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        </div>

        <ToastContainer />
        <h2 className="text-2xl font-semibold flex justify-center mb-[80px]">
          ثبت نام
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="username"
              className="block mr-2 mb-2 text-sm font-medium text-gray-600"
            >
              نام کاربری:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formval.username}
              onChange={handleChange}
              className={`mt-1 p-2 bg-[#EDEDEE] pr-3 w-full rounded-[20px] focus:outline-none ${
                errors.username ? "border border-red-500" : ""
              }`}
            />
            {errors.username && (
              <span className="text-red-500">{errors.username}</span>
            )}
          </div>

          <div className="mb-3">
            <label
              htmlFor="password"
              className="block mb-2 mr-2 text-sm font-medium text-gray-600"
            >
              رمزعبور:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formval.password}
              onChange={handleChange}
              className={`mt-1 p-2 pr-3 bg-[#EDEDEE] w-full rounded-[20px] focus:outline-none ${
                errors.password ? "border border-red-500" : ""
              }`}
            />
            {errors.password && (
              <span className="text-red-500">{errors.password}</span>
            )}
          </div>

          <div className="mb-3">
            <label
              htmlFor="phone_number"
              className="block rtl mr-2 mb-2 text-sm font-medium text-gray-600"
            >
              شماره تلفن:
            </label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={formval.phone_number}
              onChange={handleChange}
              className={`mt-1 p-2 bg-[#EDEDEE] pr-3 w-full rounded-[20px] focus:outline-none ${
                errors.phone_number ? "border border-red-500" : ""
              }`}
            />
            {errors.phone_number && (
              <span className="text-red-500">{errors.phone_number}</span>
            )}
          </div>

          <button
            type="submit"
            className="w-full mt-5 flex items-center justify-center bg-blue-500 text-white p-3 rounded hover:bg-blue-600 focus:outline-none"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" color="default" /> : "ثبت نام"}
          </button>
        </form>
        {errors.server && (
          <div className="text-red-500 mt-3">{errors.server}</div>
        )}
        <div className="flex mt-5 mr-1 text-blue-500 w-full ">
          <Link to={"/login"}>قبلاً ثبت نام کرده اید؟ ورود</Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
