import React, { useRef } from "react";
import Deleteslider from "./Deleteslider";
import { Chip } from "@nextui-org/react";
const Sliderl = (props) => {
  const element = useRef(null);
  const temphidden = () => {
    element.current.classList.add("none");
  };

  return (
    <div
      ref={element}
      dir="rtl"
      className="w-full flex justify-between items-center rounded-md bg-[#e6e6e8] px-4 py-3 my-1"
    >
      <div className="zero">{props.name}</div>
      <div className="flex items-center zero">
        <Chip className="ml-3 pt-1" color="primary">
          {props.tag}
        </Chip>
        <Deleteslider
          err={props.err}
          fnc={props.fnc}
          func={temphidden}
          succ={props.succ}
          name={props.name}
        />
      </div>
    </div>
  );
};

export default Sliderl;
