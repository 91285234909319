import React, { useRef } from "react";
import Deletepost from "./Deletepost";
import { Chip } from "@nextui-org/react";
import Editstock from "./Editstock";

const Productl = (props) => {
  const element = useRef(null);
  const temphidden = () => {
    element.current.classList.add("none");
  };

  return (
    <div
      ref={element}
      dir="rtl"
      className="w-full relative flex justify-between items-center rounded-md bg-[#e6e6e8] px-4 py-3 my-1"
    >
      <div className="zero">{props.name}</div>
      <div className="flex zero items-center">
        <Chip className="absolute left-[140px] pt-1" color="primary">
          {props.tag}
        </Chip>
        <Editstock
          fnc={props.fnc}
          err={props.err}
          succ={props.succ}
          stock={props.stock}
          price={props.price}
          discounted={props.discounted}
          name={props.name}
        />
        <Deletepost
          func={temphidden}
          err={props.err}
          succ={props.succ}
          name={props.name}
        />
      </div>
    </div>
  );
};

export default Productl;
