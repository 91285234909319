import React, { useEffect, useState, useCallback } from "react";
import { FaUser, FaShoppingBasket } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";

const Acc = () => {
  const [isAd, setAd] = useState(false);
  const navigate = useNavigate();

  const handlecart = () => {
    if (!isUserLoggedIn()) {
      navigate("/login");
    } else {
      navigate("/cart");
    }
  };

  const handleacc = () => {
    if (!isUserLoggedIn()) {
      navigate("/login");
    }
  };

  const orderhandler = () => {
    if (isAd) {
      navigate("/admin");
    } else {
      navigate("/orders");
    }
  };

  const carthandler = () => {
    navigate("/cart");
  };

  const resetpasshandler = () => {
    navigate("/resetpassword");
  };

  const logouthandler = () => {
    localStorage.setItem("token", null);
    localStorage.setItem("username", null);
    navigate("/");
  };

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("token") || "null";
    const username = localStorage.getItem("username") || "null";
    return token !== "null" && username !== "null";
  };

  const checkAdmin = useCallback(async () => {
    const response = await fetch("/api/admin/is_admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });
    if (response.ok) {
      setAd((await response.text()) === "true");
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn()) {
      checkAdmin();
    }
  }, [checkAdmin]);

  return (
    <div className="flex sc">
      <Button
        onClick={handlecart}
        className="ml-[20px] p-3 flex items-center text-[#029C94] bg-[#d5e5e3]"
        aria-label="Shopping Cart"
      >
        سبد خرید
        <FaShoppingBasket />
      </Button>

      <Dropdown>
        <DropdownTrigger>
          <Button
            onClick={handleacc}
            className="cur flex items-center p-3 text-[#029C94] bg-[#d5e5e3]"
            aria-label="User Account"
          >
            {isUserLoggedIn() ? (
              <h3 className="mt-[3px] max-w-[120px] overflow-hidden">
                {localStorage.getItem("username")}
              </h3>
            ) : (
              <h3>ورود/ثبت نام</h3>
            )}
            <FaUser />
          </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label="User Actions">
          <DropdownItem onClick={orderhandler} key="new">
            {isAd ? "پنل" : " سفارش ها"}
          </DropdownItem>
          <DropdownItem onClick={carthandler} key="copy">
            سبد خرید
          </DropdownItem>
          <DropdownItem onClick={resetpasshandler} key="edit">
            بازیابی اطلاعات شخصی
          </DropdownItem>
          <DropdownItem
            onClick={logouthandler}
            key="delete"
            className="text-danger"
            color="danger"
          >
            خروج از حساب کاربری
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Acc;
