import React from "react";
import Cover from "../assets/success.avif";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Button } from "@nextui-org/react";

const OrderSuccess = () => {
    return (
      <>
        <Navbar />
        <div className=" app h-[100vh] flex justify-center items-center">
          <div className="flex items-center flex-col">
            <img src={Cover} className="w-[600px]" alt="cover" />

            <h1 className="text-4xl text-[#2fb825] font-bold">
              پرداخت با موفقیت انجام شد !!!
            </h1>
            <Button className="bg-[#2fb825] p-0 mt-7 text-white">
              <Link className="w-full flex items-center p-4 h-full" to={"/orders"}>
                سفارش ها!!!
              </Link>
            </Button>
          </div>
        </div>
        <Footer />
      </>
    );
};

export default OrderSuccess;
