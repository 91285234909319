import React, { useState } from "react";
import { FaTrashCan } from "react-icons/fa6";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
} from "@nextui-org/react";
import { Spinner } from "@nextui-org/react";

const Deletepost = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const Deletehandler = async () => {
    setIsLoading(true);
    const response = await fetch("/api/admin/posts/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        name: props.name,
      }),
    });

    if (response.ok) {
      props.func();
      props.succ("محصول با موفقیت حذف شد");
    } else {
      props.err("مشکلی پیش آمد بعدا تلاش کنید");
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <div className="">
      <button
        onClick={onOpen}
        className="p-3  rounded-lg border-solid border-2 border-[#99999e] 
                 transition-transform transform-gpu 
                  hover:text-white hover:scale-110 active:scale-95"
      >
        <FaTrashCan className="text-[#6673DD]" />
      </button>
      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
        }}
      >
        <ModalContent className="w-auto dldldl">
          <ModalBody className="zero inline-block">
            <h3 className="mt-3 mb-6">آیا از حذف این محصول اطمینان دارید؟</h3>
          </ModalBody>
          <ModalFooter className="pt-1  pb-2 ">
            <Button onPress={onClose} color="primary" variant="light">
              بستن
            </Button>
            <Button onClick={Deletehandler} color="danger" type="submit">
              {isLoading ? (
                // Render the loader if isLoading is true
                <Spinner size="sm" color="default" />
              ) : (
                // Render your actual content once loading is complete
                <span>حذف محصول</span>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Deletepost;
