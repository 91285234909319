import React, { useEffect, useState, useCallback, useMemo } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import ProductShortcut from "./Productshortcut";
import { Spinner, Pagination } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import Notfound from "./Notfound";

const Seeall = () => {
  String.prototype.replacePlusWithSpace = function () {
    return this.replace(/\+/g, " ");
  };
  const [isfound, setfound] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { id } = useParams();
  const modifiedid = id.replacePlusWithSpace();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(3);

  const fetchData = useCallback(
    async (page) => {
      if (data[page]) {
        setPage(page);
        setLoading(false);
      } else {
        const response = await fetch("/api/posts/list", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            null_image: false,
            image_per_page: 30,
            only_in_stock: true,
            page_number: page,
            tag: modifiedid,
          }),
        });
        if (response.ok) {
          const { posts, count } = await response.json();
          if (posts.length === 0) {
            navigate("/404");
            return;
          }
          setData((prevData) => ({ ...prevData, [page]: posts }));
          setTotalPages(
            count % 30 === 0 ? count / 30 : Math.floor(count / 30) + 1
          );
          setPage(page);
          setLoading(false);
        }
      }
    },
    [modifiedid, data]
  );

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const handlePageChange = (page) => {
    setLoading(true);
    setPage(page);
  };

  const productShortcuts = useMemo(() => {
    return data[page]?.map((product) => (
      <div
        key={product.name}
        className="zero sealprodcon pt-10 !mx-auto inline-block"
      >
        <div className="w-[97%] seallprod h-full ">
          <ProductShortcut
            image={product.image}
            discountPrice={product.discounted_price}
            name={product.name}
            writer={product.author}
            price={product.price}
            type={product.type}
          />
        </div>
      </div>
    ));
  }, [data, page]);

  return (
    <div className="Abook" aria-label="See All Products">
      <Navbar />
      {loading ? (
        <div className="w-full h-[100vh] flex items-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="app flex justify-center flex-wrap  pt-[120px] pb-[50px]">
            {productShortcuts}
          </div>
          <div className="flex  my-10 justify-center">
            <Pagination
              className="bg-gray-400 p-5 rounded-2xl"
              total={totalPages}
              initialPage={page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Seeall;
