import React, { useEffect, useState, useMemo } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import ProductShortcut from "./Productshortcut";
import { Spinner } from "@nextui-org/react";
import Notfound from "./Notfound";

const Searchres = () => {
  const { id } = useParams();
  const [data, setdata] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);

    const response = await fetch("/api/posts/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: id,
        only_in_stock: true,
      }),
    });

    if (response.ok) {
      const result = await response.json();
      setdata(result);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const productShortcuts = useMemo(() => {
    return data?.map((product) => (
      <div
        className="zero sealprodcon pt-10 !mx-auto inline-block"
        key={product.id}
      >
        <div className="w-[97%] seallprod h-full">
          <ProductShortcut
            image={product.image}
            discountPrice={product.discounted_price}
            name={product.name}
            writer={product.author}
            price={product.price}
            type={product.type}
          />
        </div>
      </div>
    ));
  }, [data]);

  return (
    <div className="Abook" aria-label="Search Results">
      <Navbar value={id} />
      <div
        className={`app flex justify-center flex-wrap ${
          data?.length === 0 ? "" : "pt-[120px]"
        }  pb-[50px]`}
      >
        {loading ? (
          <div className="h-[80vh] items-center flex">
            <Spinner />
          </div>
        ) : data && data.length === 0 ? (
          <Notfound none={true} />
        ) : (
          productShortcuts
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Searchres;
