import React from "react";
import { Link } from "react-router-dom";

import ImageComponent from "../assets/miancover.avif";
import Audiobook from "../assets/audiob.avif";
import Pdfb from "../assets/ebook.avif";

const Hero = () => {
  return (
    <div className="hero-con pt-[140px] hero w-full  flex mb-10">
      <div className="overflow-hidden mainslide zero w-[70%] rounded-lg ">
        <Link to="/about" aria-label="About">
          <img
            className="w-full h-full"
            src={ImageComponent}
            alt="کاور سپید قلم"
          />
        </Link>
      </div>
      <div className="sideslide flex justify-between flex-col w-[29%] h-auto">
        <div className="cover overflow-hidden zero side-sub w-full rounded-lg h-[49.3%] ">
          <Link to="/abook" aria-label="Audio Books">
            <img
              className="w-full h-full"
              src={Audiobook}
              alt="کاور کتاب صوتی"
            />
          </Link>
        </div>

        <div className="cover overflow-hidden zero side-sub w-full rounded-lg h-[49.3%] ">
          <Link to="/ebook" aria-label="E-Books">
            <img className="w-full h-full" src={Pdfb} alt="کاور کتاب الکترونیکی" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
