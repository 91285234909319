import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Input } from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/react";
import { Textarea } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { NextUIProvider } from "@nextui-org/react";
import { FiPlusSquare } from "react-icons/fi";
import { RadioGroup, Radio } from "@nextui-org/react";
import { Spinner } from "@nextui-org/react";

const Crproduct = forwardRef((props, ref) => {
  const [disable, setdisable] = useState(true);
  const [discount, setdiscount] = useState(null);
  const [score, setscore] = useState("6");
  const [ischecked, setischecked] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState("کتاب فیزیکی");
  const [formValues, setFormValues] = useState({
    productName: "",
    productPrice: "",
    authorName: "",
    tagSlider: "",
    productTitle: "",
    productDescription: "",
    file: null,
    bookfile: null,
  });
  const [Errors, setErrors] = useState({});
  const [tagsdata, setTagData] = useState();
  const [servererr, setservererr] = useState(null);
  const [btyp, setbtyp] = useState("");

  useEffect(() => {
    if (selected !== "کتاب فیزیکی") {
      setdisable(false);
    } else {
      setdisable(true);
      setbtyp("");
    }
    if (selected == "کتاب صوتی") {
      setbtyp(".mp3");
    }
    if (selected == "کتاب الکترونیکی") {
      setbtyp(".pdf");
    }
  }, [selected]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedFile = e.target.files[0];
      const fileType = selectedFile.type;

      if (fileType === "audio/mpeg") {
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64Data = event.target.result;
          const base64String = base64Data.split(",")[1];
          const getBase64StringFromDataURL = (dataURL) =>
            dataURL.replace("data:", "").replace(/^.+,/, "");
          setFormValues({
            ...formValues,
            bookfile: base64String,
          });
        };
        reader.readAsDataURL(selectedFile);
      } else if (fileType === "application/pdf") {
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64Data = event.target.result;
          const base64String = base64Data.split(",")[1];
          const getBase64StringFromDataURL = (dataURL) =>
            dataURL.replace("data:", "").replace(/^.+,/, "");
          setFormValues({
            ...formValues,
            bookfile: base64String,
          });
        };
        reader.readAsDataURL(selectedFile);
      } else {
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64Data = event.target.result;
          const base64String = base64Data.split(",")[1];
          const getBase64StringFromDataURL = (dataURL) =>
            dataURL.replace("data:", "").replace(/^.+,/, "");
          setFormValues({
            ...formValues,
            file: base64String,
          });
        };
        reader.readAsDataURL(selectedFile);
      }
    } else {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });

      setErrors({
        ...Errors,
        [e.target.name]: "",
      });
    }
  };
  async function sliderfetchData() {
    const response = await fetch("/api/slider/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const result = await response.json();
      setTagData(result);
    }
  }
  function persianToEnglish(str) {
    if (str) {
      const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
      const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

      let newStr = "";
      for (let i = 0; i < str.length; i++) {
        const char = str[i];
        const index = persianDigits.indexOf(char);
        if (index !== -1) {
          newStr += englishDigits[index];
        } else {
          newStr += char;
        }
      }
      return parseInt(newStr);
    }
  }

  useEffect(() => {
    sliderfetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const jsonData = {
      login_data: {
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      },
      image: formValues.file,
      name: formValues.productName.trim(),
      price: persianToEnglish(formValues.productPrice),
      author: formValues.authorName,
      slider_tag: formValues.tagSlider,
      title: formValues.productTitle,
      description: formValues.productDescription,
      file: formValues.bookfile,
      type: selected,
      in_stock: ischecked === "" ? 0 : parseInt(ischecked),
      score: parseInt(score),
      discounted_price: discount,
      force_file: false,
      force_image: false,
    };

    const validationMessages = {
      productName: "نام محصول خالی است",
      productPrice: "قیمت خالی است",
      authorName: "نام نویسنده خالی است",
      tagSlider: "تگ خالی است",
      productTitle: "تیتر محصول خالی است",
      productDescription: "توضیحات محصول خالی است",
      file: "عکس خالی است",
      bookfile: disable === false ? "فایل خالی است" : "",
    };
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = validationMessages[key];
      }
    });

    if (
      Object.keys(newErrors).length === (btyp === "" ? 1 : 0) &&
      (Errors.bookfile === "" || Errors.bookfile === undefined)
    ) {
      setIsLoading(true);
      const response = await fetch("/api/admin/posts/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      });
      if (response.ok) {
        handleModalClose();
        props.func();
        props.succ("محصول با موفقیت اضافه شد");
      } else if (response.status === 409) {
        setservererr("محصولی با این نام قبلا اضافه شده");
      } else {
        setservererr("مشکلی پیش آمد لطفا بعدا تلاش کنید");
      }

      setIsLoading(false);
    } else if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
  };
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedbookFileName, setSelectedbookFileName] = useState("");
  const updateSelectedbookFileName = (event) => {
    Errors.file = "";

    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedbookFileName(selectedFile.name);
    } else {
      setSelectedbookFileName("");
    }
  };

  const updateSelectedFileName = (event) => {
    Errors.bookfile = "";
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile.name);
    } else {
      setSelectedFileName("");
    }
  };

  const handleModalClose = () => {
    setFormValues({
      productName: "",
      productPrice: "",
      authorName: "",
      tagSlider: "",
      productTitle: "",
      productDescription: "",
      file: null,
      bookfile: null,
    });
    setSelected("کتاب فیزیکی");
    setSelectedFileName("");
    setSelectedbookFileName("");
    setErrors({});
    setservererr(null);
    onClose();
  };
  useImperativeHandle(ref, () => {
    return {
      function: sliderfetchData,
    };
  });

  useEffect(() => {
    setSelectedFileName("");
    setFormValues({
      ...formValues,
      bookfile: null,
    });
  }, [btyp]);
  return (
    <NextUIProvider>
      <div className="w-full mb-7 px-3 flex items justify-between pt-3">
        <h3 className="text-lg flex items-center">محصولات</h3>
        <Button
          onPress={onOpen}
          className="px-3 py-2 rounded-lg bg-[#8b97f4] flex items-center text-white"
        >
          اضافه کردن <FiPlusSquare className="text-white mr-1" />
        </Button>

        <Modal
          backdrop="opaque"
          isOpen={isOpen}
          onClose={handleModalClose}
          classNames={{
            backdrop:
              "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
          }}
        >
          <ModalContent>
            <form onSubmit={handleSubmit}>
              <ModalBody className="zero inline-block">
                <div className="flex ">
                  <div className="mt-5 w-[48%]">
                    <Input
                      size="sm"
                      type="text"
                      label="نام محصول"
                      name="productName"
                      value={formValues.productName}
                      onChange={handleChange}
                    />
                    {Errors.productName && (
                      <p className="text-red-500 mt-1 text-sm pt-1 pr-1">
                        {Errors.productName}
                      </p>
                    )}
                  </div>
                  <div className="mt-5 w-[48%]">
                    <div>
                      <Input
                        size="sm"
                        type="number"
                        pattern="[0-9]"
                        label="قیمت محصول"
                        name="productPrice"
                        value={formValues.productPrice}
                        onChange={handleChange}
                      />
                    </div>
                    {Errors.productPrice && (
                      <p className="text-red-500 mt-1 text-sm pt-1 pr-1">
                        {Errors.productPrice}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex mt-2">
                  <Select
                    aria-label
                    defaultSelectedKeys={[`item_${score}`]}
                    className=" w-[48%]"
                    size="sm"
                    onChange={(e) =>
                      setscore(
                        parseInt(e.target.value.match(/\d+/)[0], 10).toString()
                      )
                    }
                    disabledKeys={[`item_${score}`]}
                  >
                    {Array.from({ length: 10 }, (_, index) => (
                      <SelectItem key={`item_${(10 - index).toString()}`}>
                        {(10 - index).toString()}
                      </SelectItem>
                    ))}
                  </Select>
                  <div className="w-[48%]">
                    <Input
                      type="text"
                      size="sm"
                      label="تخفیف"
                      name="discount"
                      onChange={(e) => setdiscount(parseInt(e.target.value))}
                      onKeyPress={(event) => {
                        const validChars = [
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "۰",
                          "۱",
                          "۲",
                          "۳",
                          "۴",
                          "۵",
                          "۶",
                          "۷",
                          "۸",
                          "۹",
                        ];
                        if (!validChars.includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex mt-2">
                  <div className="w-[42%]">
                    <Select
                      isDisabled={tagsdata?.length == 0}
                      aria-label
                      dir="rtl"
                      size="sm"
                      placeholder=" تگ اسلایدر "
                      className=" w-full"
                      name="tagSlider"
                      value={formValues.tagSlider}
                      onChange={(value) => {
                        setFormValues({
                          ...formValues,
                          tagSlider: value.target.value,
                        });
                        Errors.tagSlider = "";
                      }}
                    >
                      {tagsdata && tagsdata.length > 0 ? (
                        tagsdata.map((content) => (
                          <SelectItem key={content.tag}>
                            {`${content.tag}`}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem>
                          <Spinner size="sm" className="mr-3 mt-1" />
                        </SelectItem>
                      )}
                    </Select>
                    {Errors.tagSlider && (
                      <p className="text-red-500 mt-1 pr-1 text-sm">
                        {Errors.tagSlider}
                      </p>
                    )}
                  </div>
                  <div dir="ltr" className="w-[60%] overflow-hidden mr-2">
                    <div className="flex w-full   items-center justify-between px-2  bg-gray-100 rounded-lg h-[55px] ">
                      <Button isDisabled={disable} size="sm" color="primary">
                        <label
                          htmlFor="authorNaame"
                          className="text-white   w-full  h-full flex justify-center items-center text-[13px] mx-0  rounded-lg cursor-pointer  focus:outline-none focus:ring "
                        >
                          بارگذاری
                        </label>
                      </Button>
                      <input
                        type="file"
                        size="sm"
                        name="bookfile"
                        id="authorNaame"
                        className="hidden cl"
                        accept={btyp}
                        onChange={(event) => {
                          updateSelectedFileName(event);
                          handleChange(event);
                        }}
                      />
                      <div className="mt-1 mx-0">
                        {selectedFileName ? (
                          <p className=" mx-0 text-[12px] overflow-hidden text-gray-700">
                            {selectedFileName}
                          </p>
                        ) : (
                          <p className=" mx-0   text-[10px] text-gray-500">
                            {" "}
                            فایل را بارگذاری کنید
                          </p>
                        )}
                      </div>
                    </div>
                    {Errors.bookfile && (
                      <p className="text-red-500 text-right mt-1 pr-1 text-sm">
                        {Errors.bookfile}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[48%]">
                    <Input
                      type="text"
                      size="sm"
                      label="نام نویسنده"
                      className="mt-2"
                      name="authorName"
                      value={formValues.authorName}
                      onChange={handleChange}
                    />
                    {Errors.authorName && (
                      <p className="text-red-500 mt-1 text-sm pt-1 pr-1">
                        {Errors.authorName}
                      </p>
                    )}
                  </div>

                  <div className="w-[48%]">
                    <Input
                      type="text"
                      size="sm"
                      label="تیتر محصول"
                      name="productTitle"
                      className="mt-2  w-[100%]"
                      value={formValues.productTitle}
                      onChange={handleChange}
                    />
                    {Errors.productTitle && (
                      <p className="text-red-500 mt-1 pr-1 text-sm">
                        {Errors.productTitle}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  dir="ltr"
                  className=" flex justify-between mt-2 p-2 bg-gray-100 rounded-lg items-center"
                >
                  <label
                    htmlFor="authorName"
                    className="text-white bg-blue-500 mx-0 py-2 px-4 rounded-lg cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                  >
                    بارگذاری
                  </label>
                  <input
                    type="file"
                    size="sm"
                    name="file"
                    id="authorName"
                    className="hidden"
                    onChange={(event) => {
                      updateSelectedbookFileName(event);
                      handleChange(event);
                    }}
                    accept=".jpg, .jpeg, .png, .webp, .avif"
                  />
                  <div className="mt-1 mx-0">
                    {selectedbookFileName ? (
                      <p className=" mx-0 text-gray-700">
                        {selectedbookFileName}
                      </p>
                    ) : (
                      <p className=" mx-0 ml-1 text-sm text-gray-500">
                        عکس را بارگذاری کنید
                      </p>
                    )}
                  </div>
                </div>
                {Errors.file && (
                  <p className="text-red-500 text-sm mt-2">{Errors.file}</p>
                )}

                <h1 className=" h-[10px] w-full"></h1>
                <Textarea
                  disableAutosize
                  placeholder="توضیحات محصول "
                  className="w-full  zero mt-4"
                  name="productDescription"
                  value={formValues.productDescription}
                  onChange={handleChange}
                />
                {Errors.productDescription && (
                  <p className="text-red-500 my-1 pr-1 text-sm">
                    {Errors.productDescription}
                  </p>
                )}
                <RadioGroup
                  orientation="horizontal"
                  className="mt-2"
                  value={selected}
                  onValueChange={setSelected}
                >
                  <Radio value="کتاب فیزیکی">
                    {" "}
                    <span className="mr-1 text-gray-500"> فیزیکی </span>
                  </Radio>
                  <Radio value="کتاب صوتی">
                    {" "}
                    <span className="mr-1 text-gray-500"> صوتی </span>
                  </Radio>
                  <Radio value="کتاب الکترونیکی">
                    {" "}
                    <span className="mr-1 text-gray-500"> الکترونیکی </span>
                  </Radio>
                </RadioGroup>

                {servererr && (
                  <p className="text-red-500 mt-1 text-sm pt-1 pr-1">
                    {servererr}
                  </p>
                )}
              </ModalBody>
              <ModalFooter dir="rtl" className="flex items-center pt-3 pb-2">
                <div className="h-full text-gray-500 flex items-center  ">
                  <Input
                    type="text"
                    size="sm"
                    defaultValue={ischecked}
                    className="max-w-xs"
                    placeholder=" موجودی "
                    onChange={(e) => setischecked(e.target.value)}
                    onKeyPress={(event) => {
                      const validChars = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "۰",
                        "۱",
                        "۲",
                        "۳",
                        "۴",
                        "۵",
                        "۶",
                        "۷",
                        "۸",
                        "۹",
                      ];
                      if (!validChars.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <Button
                  color="danger"
                  variant="light"
                  onPress={handleModalClose}
                >
                  بستن
                </Button>
                <Button isDisabled={isLoading} color="primary" type="submit">
                  {isLoading ? (
                    <Spinner size="sm" color="default" />
                  ) : (
                    <span>اضافه کردن</span>
                  )}
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </div>
    </NextUIProvider>
  );
});

export default Crproduct;
