import React, { useEffect, useState, useMemo } from "react";
import Slider from "./Slider";
import Discounted from "./Discounted";

const Sliderscon = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/slider/list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const result = await response.json();
        setdata(await result);
      }
    };

    fetchData();
  }, []);

  const sliders = useMemo(
    () =>
      data.map((slide, index) => (
        <Slider
          key={index}
          tag={slide.tag}
          name={slide.name}
          alt={`Slider ${slide.name}`}
        />
      )),
    [data]
  );

  return (
    <>
      <Discounted name={"فروش ویژه"} />
      {sliders}
    </>
  );
};

export default Sliderscon;
