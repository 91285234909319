import React, { useState, useEffect, useRef } from "react";
import { FaBasketShopping } from "react-icons/fa6";
import { Button, Spinner } from "@nextui-org/react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Cart from "./Cart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Shopingcart = () => {
  const [price, setprice] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [len, setlen] = useState(0);

  const errornotif = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };
  const successnotif = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  useEffect(() => {
    fetchPrice();
    fetchData();
    if (
      localStorage.getItem("token") == "null" ||
      localStorage.getItem("token") == "null"
    ) {
      navigate("/login");
    }
  }, []);

  function formatNumberWithCommas(number) {
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const fetchPrice = async () => {
    const response = await fetch("/api/cart/price", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        name: "",
      }),
    });
    if (response.ok) {
      setprice(await response.json());
    } else if (response.status == 401) {
      localStorage.setItem("token", null);
      localStorage.setItem("username", null);
      navigate("/login");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch("/api/cart/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });

    if (response.ok) {
      const fetchedData = await response.json();
      let newArray = [];

      for (let key in fetchedData) {
        if (fetchedData.hasOwnProperty(key)) {
          newArray.push({
            name: key,
            count: fetchedData[key],
          });
        }
      }
      setData(newArray);
      setlen(newArray.length);
      setLoading(false);
    } else if (response.status === 401) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (data) {
      const totalCount = data.reduce((acc, count) => acc + count.count, 0);
      setlen(totalCount);
    }
  }, [data]);

  const changehandler = (leng, discount, pricedd) => {
    if (leng) {
      setlen(len + leng);
    }
    if (discount && pricedd) {
      setprice({
        price: price.price + pricedd,
        discounted_price: price.discounted_price + discount,
      });
    } else {
      setprice({
        price: price.price + pricedd,
        discounted_price: price.discounted_price + pricedd,
      });
    }
  };

  return (
    <div className="flex overflow-hidden flex-col ">
      <ToastContainer />
      <Navbar />
      {loading ? (
        <div className="mt-[120px] h-[80vh] w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="app  pt-[140px] mb-10 ">
          {len <= 0 ? (
            <div className="flex flex-col items-center justify-center p-10 bg-white rounded-2xl shadow-xl w-full h-[70vh]">
              <div className="cursor-pointer zero p-[50px] rounded-full bg-[#d5e5e3]">
                <FaBasketShopping className="text-[110px] text-[#029C94]" />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="text-3xl mt-10">سبد خرید شما خالی است!</h3>
                <div className="mt-5">
                  <Button className="w-auto text-md text-white bg-[#0fb6b0]">
                    <Link to="/">بازگشت به صفحه اصلی</Link>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex ffffc">
              <div className="w-[70%] fbo zero">
                <h2 className="zero pr-2 text-2xl">محصولات سبد خرید</h2>
                <div className="w-full mb-10 p-2 h-full">
                  {data &&
                    data.map((product) => (
                      <Cart
                        err={errornotif}
                        succ={successnotif}
                        fnc={changehandler}
                        key={product.name}
                        name={product.name}
                        count={product.count}
                      />
                    ))}
                </div>
              </div>
              <div className="w-[30%] overflow-hidden sbo ">
                <div className="w-full mt-[55px] h-full">
                  <div className="w-full h-[404px] rounded-2xl mb-10 p-4 bg-white shadow-md">
                    <div className="w-full h-full flex flex-col ">
                      <div className="w-full flex pt-1 border-b-2 pb-5 justify-center text-gray-700 text-2xl ">
                        فاکتور خرید
                      </div>
                      <div className="flex w-full justify-between px-2 py-3 border-b-2">
                        <h3 className="text-lg">تعداد کالا</h3>
                        <p className="text-lg">{len?.toLocaleString("fa")}</p>
                      </div>
                      <div className="flex w-full justify-between px-2 py-3 border-b-2">
                        <h3 className="text-lg">جمع کل</h3>
                        <p className=" text-lg">{` ${formatNumberWithCommas(
                          price?.price?.toLocaleString("fa")
                        )} تومان`}</p>
                      </div>
                      <div className="flex w-full justify-between px-2 py-3 border-b-2">
                        <h3 className="text-lg">قابل پرداخت</h3>
                        <p className=" text-lg">{` ${formatNumberWithCommas(
                          price?.discounted_price?.toLocaleString("fa")
                        )} تومان`}</p>
                      </div>
                      <Button
                        onClick={() => navigate("/checkout")}
                        className="mt-auto"
                        color="primary"
                      >
                        تایید و ثبت سفارش
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Shopingcart;
