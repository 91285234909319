import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import notfoundcover from "../assets/notfound.avif";
import { Button } from "@nextui-org/react";

const Notfound = (props) => {
  const { none } = props;

  return (
    <>
      {none !== true && <Navbar />}
      <div className="w-full h-[100vh] flex flex-col justify-center items-center">
        <img className="w-[600px]" src={notfoundcover} alt="Not Found" />
        <h1 className="text-4xl text-[#c9455d] font-bold">
          چیزی که دنبالش میگردی رو پیدا نکردیم!!!
        </h1>
        <Button className="bg-[#E14154] p-0 mt-7 text-white">
          <Link className="w-full flex items-center p-4 h-full" to={"/"}>
            برگشت به صفحه اصلی
          </Link>
        </Button>
      </div>
      {none !== true && <Footer />}
    </>
  );
};

export default Notfound;
