import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Card,
  CardBody,
  Modal,
  ModalContent,
  Input,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import moment from "jalali-moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaRegCopy } from "react-icons/fa6";

const AdminOrders = (props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [currentOrder, setCurrentOrder] = useState();

  const [inprocess, setProcess] = useState([]);
  const [tempinprocess, settempProcess] = useState([]);

  const [inshiping, setShiping] = useState([]);
  const [tempinshiping, settempShiping] = useState([]);

  const [fininshed, setFinished] = useState([]);
  const [tempfininshed, settempFinished] = useState([]);

  const validationSchema = Yup.object().shape({
    numberField: Yup.number()
      .typeError("حتما باید عدد باشد")
      .required("لطفا این فیلد را پر کنید"),
  });

  function persianToEnglish(value) {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    const englishDigits = "0123456789";
    let newValue = "";
    if (!value.split("").some((char) => persianDigits.includes(char))) {
      return value;
    }

    for (let i = 0; i < value.length; i++) {
      const ch = value.charAt(i);
      const index = persianDigits.indexOf(ch);
      if (index > -1) {
        newValue += englishDigits.charAt(index);
      } else {
        newValue += ch;
      }
    }
    return newValue;
  }
  const formik = useFormik({
    initialValues: { numberField: "" },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const englishNumber = persianToEnglish(values.numberField);
      setStage(englishNumber);
    },
  });

  const setStage = async (code) => {
    const response = await fetch("/api/cart/set_order_stage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        username_and_order: {
          username: localStorage.getItem("username"),
          order: currentOrder,
        },
        new_stage: currentOrder.stage.includes("آماده سازی")
          ? `درحال پست-${code}`
          : "تحویل شده",
      }),
    });
    if (response.ok) {
      props.succ(
        currentOrder.stage.includes("آماده سازی")
          ? "سفارش با موفقیت به مرحله درحال پست ارتقا یافت"
          : "سفارش با موفقیت به مرحله تحویل شده ارتقا یافت"
      );
      onClose();
      fetchData();
    }
  };
  async function fetchData() {
    const response = await fetch("/api/cart/list_all_orders_admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
      }),
    });
    if (response.ok) {
      const result = await response.json();
      let inprocess = [];
      let shipping = [];
      let finished = [];
      result.map((order) => {
        order.order.map((details) => {
          if (details.stage.includes("آماده سازی")) {
            inprocess.push(details);
          } else if (details.stage.includes("پست")) {
            shipping.push(details);
          } else if (details.stage.includes("تحویل شده")) {
            finished.push(details);
          }
        });
      });
      setProcess(inprocess);
      settempProcess(inprocess);

      setShiping(shipping);
      settempShiping(shipping);

      setFinished(finished);
      settempFinished(finished);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  function transformObject(inputObject) {
    const resultArray = [];

    for (const key in inputObject) {
      if (Object.hasOwnProperty.call(inputObject, key)) {
        const name = key;
        const count = inputObject[key];

        resultArray.push({ name, count });
      }
    }

    return resultArray;
  }
  const convertToPersianDate = (serverDateString) => {
    const serverDate = new Date(serverDateString);
    const jalaliDate = moment(serverDate).locale("fa").format("jD jMMMM jYYYY");
    const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const persianDateWithPersianDigits = jalaliDate.replace(
      /\d/g,
      (digit) => persianDigits[digit]
    );
    return persianDateWithPersianDigits;
  };

  const inprocess_search = (e) => {
    const search = e.target.value.toLowerCase();
    const filter = tempinprocess.filter((names) =>
      `${names.order_id}
       ${names.address_info.name}
        ${names.address_info.phone_number}
         ${names.address_info.postal_code}
          ${names.address_info.last_name}`
        .toLowerCase()
        .includes(search)
    );
    setProcess(filter);
  };

  const shipping_search = (e) => {
    const search = e.target.value.toLowerCase();
    const filter = tempinshiping.filter((names) =>
      `${names.order_id}
       ${names.address_info.name}
        ${names.address_info.phone_number}
         ${names.address_info.postal_code}
          ${names.address_info.last_name}`
        .toLowerCase()
        .includes(search)
    );
    setShiping(filter);
  };

  const finished_search = (e) => {
    const search = e.target.value.toLowerCase();
    const filter = tempfininshed.filter((names) =>
      `${names.order_id}
       ${names.address_info.name}
        ${names.address_info.phone_number}
         ${names.address_info.postal_code}
          ${names.address_info.last_name}`
        .toLowerCase()
        .includes(search)
    );
    setFinished(filter);
  };

  return (
    <div className="w-full p-2 mt-20 ">
      <div className="w-full">
        <h1 className="w-full border-[#CBCCCD] border-b-2 pb-8 text-gray-500 text-5xl">
          سفارش ها
        </h1>
        <div className="flex mt-10 w-full flex-col">
          <Tabs aria-label="Options">
            <Tab key=" آماده سازی" title=" آماده سازی">
              <Card className="shadow-none">
                <CardBody className="bg-[#f6f6f9] shadow-none">
                  <div className="zero w-full  flex ">
                    <input
                      onChange={(e) => inprocess_search(e)}
                      placeholder="جست و جوی سفارش"
                      className=" placeholder:text-gray-600 text-lg outline-none mb-5 pb-4  w-full bg-transparent border-[#4F6EC1] border-b-2"
                    />
                  </div>
                  {inprocess &&
                    inprocess.map((order) => (
                      <div className="itemsapp flex mb-10 flex-col">
                        <div className="text-right bg-white shadow-md rounded-lg w-full p-5">
                          <h1 className="text-2xl small:text-xl">
                            {`${convertToPersianDate(order.date)} با کد ${
                              order.order_id
                            } ${
                              order.address_info.name +
                              " " +
                              order.address_info.last_name
                            } ـ درحال آماده سازی `}
                          </h1>
                          <div className="flex w-full flex-col  mt-10 text-lg">
                            <div className="zero text-xl">
                              <span className=" text-gray-500">
                                {"محصولات: "}
                              </span>
                              {transformObject(order.products).map(
                                (prod) =>
                                  ` ${prod.name} × ${prod.count.toLocaleString(
                                    "fa"
                                  )} ،`
                              )}
                            </div>
                          </div>
                          <div className="w-full mt-5 flex flex-wrap">
                            <span className=" mx-3 mt-1">
                              <span className="text-gray-500">{"نام: "}</span>
                              {order.address_info.name}
                            </span>
                            <span className=" mx-3 mt-1">
                              <span className="text-gray-500">
                                {"نام خانوادگی: "}
                              </span>
                              {order.address_info.last_name}
                            </span>
                            <span className=" mx-3 mt-1">
                              <span className="text-gray-500">{"شهر: "}</span>
                              {order.address_info.city}
                            </span>

                            <span className=" mx-3 mt-1">
                              <span className="text-gray-500">{"استان: "}</span>
                              {order.address_info.province}
                            </span>
                            <span className=" mx-3 mt-1">
                              <span className="text-gray-500">
                                {"شماره تلفن: "}
                              </span>
                              {order.address_info.phone_number}
                            </span>
                            <span className=" mx-3 mt-1">
                              <span className="text-gray-500">
                                {"کد پستی: "}
                              </span>
                              {order.address_info.postal_code}
                            </span>
                          </div>
                          <div className="w-full text-lg mt-5">
                            <span className="text-gray-500">{"آدرس: "}</span>
                            <p className="text-md inline">
                              {order.address_info.address}
                            </p>
                          </div>
                          <div className="w-full justify-end flex mt-5">
                            <Button
                              onPress={() => {
                                setCurrentOrder(order);
                                onOpen();
                              }}
                              className="bg-[#6673DD] text-white"
                            >
                              ارتقا به مرحله پست
                            </Button>
                            <Modal
                              isOpen={isOpen}
                              onOpenChange={onOpenChange}
                              isDismissable={false}
                            >
                              <ModalContent>
                                {(onClose) => (
                                  <>
                                    <ModalBody>
                                      <form
                                        onSubmit={formik.handleSubmit}
                                        className="mt-10"
                                        id="myForm"
                                      >
                                        <Input
                                          size="lg"
                                          className="w-[400px] small:w-[250px]"
                                          type="text"
                                          variant={"underlined"}
                                          label="کد پیگیری پستی را وارد کنید"
                                          name="numberField"
                                          onChange={(e) => {
                                            e.target.value = persianToEnglish(
                                              e.target.value
                                            );
                                            formik.handleChange(e);
                                          }}
                                          value={formik.values.numberField}
                                        />
                                        {formik.errors.numberField &&
                                          formik.touched.numberField && (
                                            <div className="mt-3 text-red-500">
                                              {formik.errors.numberField}
                                            </div>
                                          )}
                                      </form>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        color="danger"
                                        variant="light"
                                        onPress={onClose}
                                      >
                                        بستن
                                      </Button>
                                      <Button
                                        type="submit"
                                        form="myForm"
                                        color="primary"
                                      >
                                        ارتقا
                                      </Button>
                                    </ModalFooter>
                                  </>
                                )}
                              </ModalContent>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    ))}
                </CardBody>
              </Card>
            </Tab>
            <Tab key=" پست" title=" پست">
              <Card className="shadow-none">
                <CardBody className="bg-[#f6f6f9] shadow-none">
                  <div className="itemsapp">
                    <div className="zero w-full  flex ">
                      <input
                        placeholder="جست و جوی سفارش"
                        className=" placeholder:text-gray-600 text-lg outline-none mb-5 pb-4  w-full bg-transparent border-[#4F6EC1] border-b-2"
                      />
                    </div>
                    {inshiping &&
                      inshiping.map((order) => (
                        <div className="itemsapp flex mb-10 flex-col">
                          <div className="text-right bg-white shadow-md rounded-lg w-full p-5">
                            <h1 className="text-2xl small:text-xl">
                              {`${convertToPersianDate(order.date)} با کد ${
                                order.order_id
                              } ${
                                order.address_info.name +
                                " " +
                                order.address_info.last_name
                              } ـ درحال پست `}
                            </h1>
                            <div className="flex w-full flex-col  mt-10 text-lg">
                              <div className="zero text-xl">
                                <span className=" text-gray-500">
                                  {"محصولات: "}
                                </span>
                                {transformObject(order.products).map(
                                  (prod) =>
                                    ` ${
                                      prod.name
                                    } × ${prod.count.toLocaleString("fa")} ،`
                                )}
                              </div>
                            </div>
                            <div className="w-full mt-5 flex flex-wrap">
                              <span className=" mx-1 mt-1">
                                <span className="text-gray-500">{"نام: "}</span>
                                {order.address_info.name}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"نام خانوادگی: "}
                                </span>
                                {order.address_info.last_name}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">{"شهر: "}</span>
                                {order.address_info.city}
                              </span>

                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"استان: "}
                                </span>
                                {order.address_info.province}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"شماره تلفن: "}
                                </span>
                                {order.address_info.phone_number}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"کد پستی: "}
                                </span>
                                {order.address_info.postal_code}
                              </span>
                            </div>
                            <div className="mt-3">
                              <span className="text-gray-500">
                                کد پیگیری پست:
                              </span>
                              <button
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    order.stage.split("-")[1]
                                  )
                                }
                                className=" text-gray-500 pt-1 px-1 mr-1 inline-block rounded-lg bg-gray-300 absolute overflow-ellipsis "
                              >
                                <span className="flex">
                                  <FaRegCopy className="mx-1 mt-[1px]" />
                                  <span className="text-black">
                                    {order.stage.split("-")[1]}
                                  </span>
                                </span>
                              </button>
                            </div>
                            <div className="w-full text-lg mt-5">
                              <span className="text-gray-500">{"آدرس: "}</span>
                              <p className="text-md inline">
                                {order.address_info.address}
                              </p>
                            </div>
                            <div className="w-full justify-end flex mt-5">
                              <Button
                                onPress={() => {
                                  setCurrentOrder(order);
                                  onOpen();
                                }}
                                className="bg-[#6673DD] text-white"
                              >
                                ارتقا به مرحله تحویل شده
                              </Button>
                              <Modal
                                isOpen={isOpen}
                                onOpenChange={onOpenChange}
                                isDismissable={false}
                              >
                                <ModalContent>
                                  {(onClose) => (
                                    <>
                                      <ModalBody>
                                        <h2 className="mt-5 text-xl">
                                          ایا از ارتقا به مرحله تحویل شده
                                          اطمینان دارید؟
                                        </h2>
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button
                                          color="danger"
                                          variant="light"
                                          onPress={onClose}
                                        >
                                          بستن
                                        </Button>
                                        <Button
                                          onPress={setStage}
                                          color="primary"
                                        >
                                          ارتقا
                                        </Button>
                                      </ModalFooter>
                                    </>
                                  )}
                                </ModalContent>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </Tab>
            <Tab key="تحویل شده" title="تحویل شده">
              <Card className="shadow-none">
                <CardBody className="bg-[#f6f6f9] shadow-none">
                  <div className="itemsapp">
                    <div className="zero w-full  flex ">
                      <input
                        onChange={(e) => finished_search(e)}
                        placeholder="جست و جوی سفارش"
                        className=" placeholder:text-gray-600 text-lg outline-none mb-5 pb-4  w-full bg-transparent border-[#4F6EC1] border-b-2"
                      />
                    </div>
                    {fininshed &&
                      fininshed.map((order) => (
                        <div className="itemsapp flex mb-10 flex-col">
                          <div className="text-right bg-white shadow-md rounded-lg w-full p-5">
                            <h1 className="text-2xl small:text-xl">
                              {`${convertToPersianDate(order.date)} با کد ${
                                order.order_id
                              } ${
                                order.address_info.name +
                                " " +
                                order.address_info.last_name
                              } ـ درحال پست `}
                            </h1>
                            <div className="flex w-full flex-col  mt-10 text-lg">
                              <div className="zero text-xl">
                                <span className=" text-gray-500">
                                  {"محصولات: "}
                                </span>
                                {transformObject(order.products).map(
                                  (prod) =>
                                    ` ${
                                      prod.name
                                    } × ${prod.count.toLocaleString("fa")} ،`
                                )}
                              </div>
                            </div>
                            <div className="w-full mt-5 flex flex-wrap">
                              <span className=" mx-1 mt-1">
                                <span className="text-gray-500">{"نام: "}</span>
                                {order.address_info.name}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"نام خانوادگی: "}
                                </span>
                                {order.address_info.last_name}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">{"شهر: "}</span>
                                {order.address_info.city}
                              </span>

                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"استان: "}
                                </span>
                                {order.address_info.province}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"شماره تلفن: "}
                                </span>
                                {order.address_info.phone_number}
                              </span>
                              <span className=" mx-3 mt-1">
                                <span className="text-gray-500">
                                  {"کد پستی: "}
                                </span>
                                {order.address_info.postal_code}
                              </span>
                            </div>
                            <div className="mt-3">
                              <span className="text-gray-500">
                                کد پیگیری پست:
                              </span>
                              <button
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    order.stage.split("-")[1]
                                  )
                                }
                                className=" text-gray-500 pt-1 px-1 mr-1 inline-block rounded-lg bg-gray-300 absolute overflow-ellipsis "
                              >
                                <span className="flex">
                                  <FaRegCopy className="mx-1 mt-[1px]" />
                                  <span className="text-black">
                                    {order.stage.split("-")[1]}
                                  </span>
                                </span>
                              </button>
                            </div>
                            <div className="w-full text-lg mt-5">
                              <span className="text-gray-500">{"آدرس: "}</span>
                              <p className="text-md inline">
                                {order.address_info.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
