import React, { useState, useCallback } from "react";
import { GrStorage } from "react-icons/gr";
import {
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from "@nextui-org/react";

const Editstock = ({ discounted, price, stock, name, fnc, succ }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputs, setInputs] = useState({
    codeval: stock,
    newInput1: price,
    newInput2: discounted === null ? 0 : discounted,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const validateInput = useCallback((name, value) => {
    let error = null;
    if (value === undefined || value === null || value === "") {
      error = "نمی‌تواند خالی باشد.";
    } else if (isNaN(value)) {
      error = "باید یک عدد باشد.";
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
    return !error;
  }, []);

  const handleInputChange = useCallback(
    (name) => (e) => {
      const value = e.target.value;
      setInputs((prev) => ({ ...prev, [name]: value }));
      validateInput(name, value);
    },
    [validateInput]
  );

  const isValidForm = useCallback(() => {
    return Object.values(errors).every((error) => !error);
  }, [errors]);

  const handleCode = useCallback(
    async (e) => {
      e.preventDefault();
      const { codeval, newInput1, newInput2 } = inputs;

      setIsLoading(true);

      if (isValidForm()) {
        try {
          const response = await fetch("/api/posts/modify_stock", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              login_data: {
                username: localStorage.getItem("username"),
                token: localStorage.getItem("token"),
              },
              name,
              in_stock: parseInt(codeval),
            }),
          });

          if (response.ok) {
            setIsOpen(false);
            fnc();
            succ("موجودی با موفقیت تغییر کرد");
          } else {
            setErrors((prev) => ({
              ...prev,
              codeval: "مشکلی پیش آمد بعدا تلاش کنید",
            }));
          }
        } catch (err) {
          setErrors((prev) => ({
            ...prev,
            codeval: "مشکلی پیش آمد بعدا تلاش کنید",
          }));
        }

        try {
          const response = await fetch("/api/posts/modify_price", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              login_data: {
                username: localStorage.getItem("username"),
                token: localStorage.getItem("token"),
              },
              name,
              price: parseInt(newInput1),
            }),
          });

          if (response.ok) {
            setIsOpen(false);
            fnc();
            succ("موجودی با موفقیت تغییر کرد");
          } else {
            setErrors((prev) => ({
              ...prev,
              codeval: "مشکلی پیش آمد بعدا تلاش کنید",
            }));
          }
        } catch (err) {
          setErrors((prev) => ({
            ...prev,
            codeval: "مشکلی پیش آمد بعدا تلاش کنید",
          }));
        }
        try {
          const response = await fetch("/api/posts/modify_discount", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              login_data: {
                username: localStorage.getItem("username"),
                token: localStorage.getItem("token"),
              },
              name,
              discount: parseInt(newInput2 === 0 ? null : newInput2),
            }),
          });

          if (response.ok) {
            setIsOpen(false);
            fnc();
            succ("موجودی با موفقیت تغییر کرد");
          } else {
            setErrors((prev) => ({
              ...prev,
              codeval: "مشکلی پیش آمد بعدا تلاش کنید",
            }));
          }
        } catch (err) {
          setErrors((prev) => ({
            ...prev,
            codeval: "مشکلی پیش آمد بعدا تلاش کنید",
          }));
        }
      }

      setIsLoading(false);
    },
    [validateInput, inputs, stock, name, fnc, succ]
  );

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
    setErrors({});
    setInputs((prev) => ({ ...prev, codeval: stock }));
  }, [stock]);

  return (
    <div>
      <Button
        isIconOnly
        className="ml-3 bg-[#728CD4]"
        onClick={() => setIsOpen(true)}
      >
        <GrStorage className="text-white "></GrStorage>
      </Button>

      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        placement={"center"}
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
        }}
      >
        <ModalContent className="w-auto dldldl">
          <form onSubmit={handleCode}>
            <ModalBody className="zero  inline-block">
              <div className="flex mb-2 ">
                <p className="text-[25px] mt-3 ml-3">موجودی: </p>
                <div className="">
                  <Input
                    onChange={handleInputChange("codeval")}
                    value={inputs.codeval}
                    color="primary"
                    type="text"
                    className=":firs w-full"
                    placeholder="موجودی"
                  />
                  {errors.codeval && (
                    <p className="mt-1 mr-1 text-red-500">{errors.codeval}</p>
                  )}
                </div>
              </div>
              <div className="flex mb-2">
                <p className="text-[25px] mt-3 ml-8">قیمت: </p>
                <div>
                  <Input
                    onChange={handleInputChange("newInput1")}
                    value={inputs.newInput1}
                    color="primary"
                    type="text"
                    className=":firs w-full"
                    placeholder="قیمت"
                  />
                  {errors.newInput1 && (
                    <p className="mt-1 mr-1 text-red-500">{errors.newInput1}</p>
                  )}
                </div>
              </div>
              <div className="flex mb-2">
                <p className="text-[25px] mt-3 ml-6">تخفیف: </p>
                <div>
                  <Input
                    onChange={handleInputChange("newInput2")}
                    value={inputs.newInput2}
                    color="primary"
                    type="text"
                    className=":firs w-full"
                    placeholder="تخفیف"
                  />
                  {errors.newInput2 && (
                    <p className="mt-1 mr-1 text-red-500">{errors.newInput2}</p>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="pt-1 w-full flex  pb-2 ">
              <Button color="danger" variant="light" onClick={handleModalClose}>
                بستن
              </Button>
              <Button color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <Spinner size="sm" color="default" />
                ) : (
                  <span>ثبت</span>
                )}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Editstock;
