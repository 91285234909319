import React from "react";
import Cover from "../assets/failed.avif";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Button } from "@nextui-org/react";
const OrderFailed = () => {
  return (
    <>
      <Navbar />
      <div className=" app h-[100vh] flex justify-center items-center">
        <div className="flex items-center flex-col">
          <img src={Cover} className="w-[600px]" alt="cover" />

          <h1 className="text-4xl text-[#c9455d] font-bold">
            پرداخت با موفقیت آمیز نبود !!!
          </h1>
          <Button className="bg-[#E14154] p-0 mt-7 text-white">
            <Link className="w-full flex items-center p-4 h-full" to={"/"}>
              برگشت به صفحه اصلی
            </Link>
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderFailed;
