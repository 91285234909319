import React, { useState, useEffect, useRef } from "react";
import { BsFeather } from "react-icons/bs";
import { FiHeadphones } from "react-icons/fi";
import { GoBook } from "react-icons/go";
import { FaBookAtlas } from "react-icons/fa6";
import { FaBasketShopping } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { HiOutlineHome } from "react-icons/hi";

const ResponsiveSidebar = () => {
  const [isAd, setAd] = useState(false);

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    const button = document.getElementById("button");

    button.classList.toggle("toggled");
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeSidebar();
    }
  };

  const Admin = async () => {
    const response = await fetch("/api/admin/is_admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      }),
    });

    if (response.ok) {
      setAd((await response.text()) === "true");
    }
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      Admin();
    }
  }, []);

  const handleBurgerClick = (event) => {
    // Prevent outside click handler when clicking on the burger button
    event.stopPropagation();
    toggleSidebar();
  };

  useEffect(() => {
    const button = document.getElementById("button");

    button.onclick = () => {
      button.classList.toggle("toggled");
    };

    // Add or remove event listener based on the isSidebarOpen state
    if (isSidebarOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      // Remove event listener on component unmount
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isSidebarOpen]);

  const navigate = useNavigate();

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("token") || "null";
    const username = localStorage.getItem("username") || "null";
    return token !== "null" && username !== "null";
  };

  const handlecart = () => {
    if (!isUserLoggedIn()) {
      navigate("/login");
    } else {
      navigate("/cart");
    }
  };

  const handleacc = () => {
    const token = localStorage.getItem("token") || "null";
    const username = localStorage.getItem("username") || "null";
    const condition = token === "null" || username === "null";

    if (condition) {
      navigate("/login");
    }
  };

  const orderhandler = () => {
    if (isAd) {
      navigate("/admin");
    } else {
      navigate("/orders");
    }
  };

  const carthandler = () => {
    navigate("/cart");
  };
  const resetpasshandler = () => {
    navigate("/resetpassword");
  };

  const logouthandler = () => {
    localStorage.setItem("token", null);
    localStorage.setItem("username", null);
    navigate("/");
  };

  return (
    <div className="flex">
      <div className="flex-grow" onClick={closeSidebar}>
        <button
          onClick={handleBurgerClick}
          className="burger"
          aria-label="burger-menu"
          id="button"
        >
          <div id="icon"></div>
        </button>
      </div>

      <div
        ref={sidebarRef}
        className={`fixed top-0 right-0 h-full w-64 bg-gray-800 p-4 text-white transform transition-transform ease-in-out duration-300 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="w-full ">
          <div>
            <Link to={"/"}>
              <div className="py-3 flex items-center text-lg bb">
                صفحه اصلی <HiOutlineHome className="ml-0 text-[17px]" />
              </div>
            </Link>

            <div className="py-3 flex items-center text-lg bb">
              <Dropdown>
                <DropdownTrigger>
                  <button
                    onClick={handleacc}
                    className="flex w-full h-full items-center"
                  >
                    {isUserLoggedIn() ? (
                      <h3 className="mt-[3px]">
                        {localStorage.getItem("username")}
                      </h3>
                    ) : (
                      <h3>ورود/ثبت نام</h3>
                    )}
                    <FaUser className="ml-0 text-[17px]" />
                  </button>
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions">
                  <DropdownItem onClick={orderhandler} key="new">
                    {isAd ? "پنل" : " سفارش ها"}
                  </DropdownItem>
                  <DropdownItem onClick={carthandler} key="copy">
                    سبد خرید
                  </DropdownItem>
                  <DropdownItem onClick={resetpasshandler} key="edit">
                    بازیابی اطلاعات شخصی
                  </DropdownItem>
                  <DropdownItem
                    onClick={logouthandler}
                    key="delete"
                    className="text-danger"
                    color="danger"
                  >
                    خروج از حساب کاربری
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>

            <div
              onClick={handlecart}
              className="py-3 flex items-center text-lg bb"
            >
              سبد خرید <FaBasketShopping className="ml-0 text-[17px]" />
            </div>

            <Link to="/abook">
              <div className="py-3 flex items-center text-lg bb">
                کتاب صوتی <FiHeadphones className="ml-0 text-[17px]" />
              </div>
            </Link>
            <Link to="/ebook">
              <div className="py-3 flex items-center text-lg bb">
                کتاب الکترونیکی
                <FaBookAtlas className="ml-0 text-[17px]" />
              </div>
            </Link>
            <Link to="/book">
              <div className="py-3 flex items-center text-lg bb">
                کتاب فیزیکی <GoBook className="ml-0 text-[17px]" />
              </div>
            </Link>
            <Link to="/about">
              <div className="py-3 flex items-center text-lg bb">
                {" "}
                درباره سپیدقلم <BsFeather className="ml-0  text-[17px]" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveSidebar;
