import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "@nextui-org/react";

const ProductShortcut = (props) => {
  const [mousedownTime, setMousedownTime] = useState(0);
  const [mamad, setMamad] = useState();

  const blobUrl = useMemo(() => {
    const blob = new Blob([new Uint8Array(props.image)], {
      type: "image/avif",
    });
    const url = URL.createObjectURL(blob);
    return url;
  }, [props.image]);

  useEffect(() => {
    return () => URL.revokeObjectURL(blobUrl);
  }, [blobUrl]);

  const handleMouseDown = () => {
    setMousedownTime(Date.now());
  };

  const handleMouseUp = () => {
    const mouseupTime = Date.now();
    const holdTime = mouseupTime - mousedownTime;

    setMamad((prevMamad) => {
      const newMamad = holdTime <= 140;
      return newMamad;
    });
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const discountedBaseAmount = props.discountPrice;
  const baseAmount = props.price;
  const formattedBaseAmount = useMemo(
    () => formatNumberWithCommas(baseAmount.toLocaleString("fa")),
    [baseAmount]
  );
  const discountedFormattedAmount = useMemo(
    () =>
      discountedBaseAmount
        ? formatNumberWithCommas(discountedBaseAmount.toLocaleString("fa"))
        : null,
    [discountedBaseAmount]
  );
  String.prototype.replaceSpacesWithPlus = function () {
    return this.replace(/ /g, "+");
  };
  return (
    <Link
      to={`/product/${props.name.replaceSpacesWithPlus()}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      draggable="false"
      className="product-link"
      onClick={(e) => {
        if (!mamad) {
          e.preventDefault();
        }
      }}
      aria-label={`Product ${props.name}`}
    >
      <div className="relative max-w-sm mx-auto bg-white shadow-md rounded-2xl overflow-hidden border-b-2">
        <div className="absolute ifncon w-full h-full index"> </div>
        <div className="aspect-w-2 aspect-h-3">
          {blobUrl ? (
            <img
              className="w-[300px] rounded-md  h-[400px]"
              src={blobUrl}
              alt={props.name}
              onError={(e) => {
                e.target.src = "path/to/fallback-image.jpg";
              }}
            />
          ) : (
            <Skeleton className="rounded-lg">
              <div className="h-[400px] rounded-lg bg-secondary"></div>
            </Skeleton>
          )}
        </div>
        <div className="p-4">
          <h4 className="text-xl text-right mr-0 font-semibold mb-2">
            {props.name}
          </h4>
          <h5 className="text-sm text-right font-light mb-2">{props.writer}</h5>
          <div className="flex items-center justify-between mb-2">
            <h5 className="px-2 py-1 text-xs bg-gray-200 text-gray-600 rounded-full ml-2">
              {props.type}
            </h5>
            <span
              className={`text-lg zero text-center font-semibold ${
                props.discountPrice ? "line-through text-gray-500" : ""
              }`}
            >
              {`${formattedBaseAmount} تومان`}
            </span>
          </div>

          {props.discountPrice && discountedFormattedAmount !== null && (
            <div className="flex justify-between items-start">
              <span className="zero text-[#098C8A]">فروش ویژه</span>
              <span className="text-lg font-semibold zero text-[#098C8A]">
                {` ${discountedFormattedAmount} تومان`}
              </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProductShortcut;
