import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const RequestPhoneNumber = () => {
  const navigate = useNavigate();

  const [send, setSend] = useState(true);
  const [formData, setFormData] = useState({
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    phoneNumber: "",
    server: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const persianToEnglish = (value) => {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    const englishDigits = "0123456789";
    const persianLetters = "ابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهی";
    const englishLetters = "abpttjcchkddzrzsstzogfqklmnhu";
    let newValue = "";
    for (let i = 0; i < value.length; i++) {
      const ch = value.charAt(i);
      const digitIndex = persianDigits.indexOf(ch);
      const letterIndex = persianLetters.indexOf(ch);
      if (digitIndex >= 0) {
        newValue += englishDigits.charAt(digitIndex);
      } else if (letterIndex >= 0) {
        newValue += englishLetters.charAt(letterIndex);
      } else {
        newValue += ch;
      }
    }
    return newValue;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = persianToEnglish(value);
    setFormData({
      ...formData,
      [name]: newValue,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({ ...errors, server: "" });

    const newErrors = {};
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "شماره نمیتواند خالی باشد";
    } else if (!/^\d+$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "شماره معتبر نیست";
    } else if (formData.phoneNumber.length !== 11) {
      newErrors.phoneNumber = "شماره معتبر نیست";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    const response = await fetch("/api/users/reset_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: formData.phoneNumber,
      }),
    });

    if (response.status === 404) {
      setErrors({ ...errors, server: "این شماره در سیستم وجود ندارد" });
    } else if (response.ok) {
      setSend(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (send === false) {
      const timer = setTimeout(() => {
        navigate("/");
        setSend(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [send]);
  return (
    <>
      {send ? (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="bg-white p-[40px] py-[50px] rounded-[30px] shadow-md w-96">
            <h2 className="text-2xl font-semibold flex justify-center mb-[80px]">
              بازیابی اطلاعات شخصی
            </h2>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
              <div className="mb-5">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 mr-2 text-sm font-medium text-gray-600"
                >
                  شماره خود را وارد کنید:
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 pr-3 bg-[#EDEDEE] w-full rounded-[20px] focus:outline-none ${
                    errors.phoneNumber ? "border border-red-500" : ""
                  }`}
                />
                {errors.phoneNumber && (
                  <div className="text-red-500">{errors.phoneNumber}</div>
                )}
              </div>
              <button
                type="submit"
                className="w-full flex bg-blue-500 justify-center items-center text-white p-3 rounded hover:bg-blue-600 focus:outline-none"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner color="default" size="sm" />
                ) : (
                  "درخواست لینک بازیابی"
                )}
              </button>
            </form>
            {errors.server && (
              <div className="text-red-500 mt-3">{errors.server}</div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full h-[100vh]">
          <h1 className="inline-block text-3xl text-cneter">
            لینک بازیابی اطلاعات شخصی برای شما ارسال شد بعد از {"5"} ثانیه به
            صفحه اصلی هدایت میشوید
          </h1>
        </div>
      )}
    </>
  );
};

export default RequestPhoneNumber;
