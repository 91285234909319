import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Navbar from "./Navbar";
import { Checkbox } from "@nextui-org/react";
import Footer from "./Footer";
import { Spinner } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Textarea,
  Button,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const [buttonloading, setbuttonloading] = useState(false);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("token") || "null";
    const username = localStorage.getItem("username") || "null";
    return token !== "null" && username !== "null";
  };

  const mamad = async (value) => {
    setbuttonloading(true);
    await fetch("/api/users/set_address_info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        name: value.name,
        last_name: value.lastName,
        province: value.state,
        city: value.city,
        postal_code: value.postcode,
        phone_number: value.phoneNumber,
        address: value.address,
      }),
    });
    setbuttonloading(false);
  };

  const [priceloading, setPriceloading] = useState(true);
  const [productloading, setProductloading] = useState(true);
  const [infoloading, setinfoloading] = useState(true);
  const [product, setProduct] = useState();
  const [price, setPrice] = useState();
  const [data, setData] = useState({
    name: "",
    lastName: "",
    state: "",
    city: "",
    address: "",
    postcode: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate("/");
    }

    const fetchProducts = async () => {
      setProductloading(true);
      const response = await fetch("/api/cart/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        }),
      });

      if (response.ok) {
        const fetchedData = await response.json();
        let newArray = [];

        for (let key in fetchedData) {
          if (fetchedData.hasOwnProperty(key)) {
            newArray.push({
              name: key,
              count: fetchedData[key],
            });
          }
        }
        setProduct(newArray);
      } else if (response.status === 401) {
        navigate("/login");
      }
      setProductloading(false);
    };
    fetchProducts();

    const fetchPrice = async () => {
      setPriceloading(true);
      const response = await fetch("/api/cart/price", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_data: {
            username: localStorage.getItem("username"),
            token: localStorage.getItem("token"),
          },
          name: "",
        }),
      });
      if (response.ok) {
        const res = await response.json();
        setPrice(res.discounted_price);
      } else if (response.status == 401) {
        localStorage.setItem("token", null);
        localStorage.setItem("username", null);
        navigate("/login");
      }
      setPriceloading(false);
    };
    fetchPrice();

    const infoget = async () => {
      setinfoloading(true);
      const response = await fetch("/api/users/get_address_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_data: {
            username: localStorage.getItem("username"),
            token: localStorage.getItem("token"),
          },
        }),
      });
      if (response.ok) {
        const res = await response.json();
        setData({
          name: res.name,
          lastName: res.last_name,
          state: res.province,
          city: res.city,
          address: res.address,
          postcode: res.postal_code,
          phoneNumber: res.phone_number,
        });
      }
      setinfoloading(false);
    };
    infoget();
  }, []);

  const [rule, setRule] = useState(true);
  const convertToEnglishNumber = (value) => {
    const persianNumbers = [
      /۰/g,
      /۱/g,
      /۲/g,
      /۳/g,
      /۴/g,
      /۵/g,
      /۶/g,
      /۷/g,
      /۸/g,
      /۹/g,
    ];
    const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    persianNumbers.forEach((persianNumber, index) => {
      value = value.replace(persianNumber, englishNumbers[index]);
    });

    return value;
  };
  function formatNumberWithCommas(number) {
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const buy = async () => {
    setbuttonloading(true);
    await fetch("/api/cart/buy", {
      redirect: "follow",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
      }),
    }).then((response) => {
      // HTTP 303 response
      if (response.redirected) {
        window.location.href = response.url;
      }
    });
    setbuttonloading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: data.name,
      lastName: data.lastName,
      state: data.state,
      city: data.city,
      address: data.address,
      postcode: data.postcode,
      phoneNumber: data.phoneNumber,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("نباید خالی باشد"),
      lastName: Yup.string().required("نباید خالی باشد"),
      state: Yup.string().required("نباید خالی باشد"),
      city: Yup.string().required("نباید خالی باشد"),
      address: Yup.string().required("نباید خالی باشد"),
      postcode: Yup.string()
        .length(10, "باید دقیقا 10 رقم باشد")
        .matches(/^\d{10}$/, "باید عدد باشد")
        .required("نباید خالی باشد"),
      phoneNumber: Yup.string()
        .required("نباید خالی باشد")
        .matches(/^(09|\+989)\d{9}$/, "شماره تلفن معتبر نیست"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!(JSON.stringify(values) === JSON.stringify(data))) {
        mamad(values);
      }
      buy();
    },
  });

  const fieldLabels = {
    name: "نام",
    lastName: "نام خانوادگی",
    state: "استان",
    city: "شهر",
    postcode: "کد پستی",
    phoneNumber: "شماره تلفن",
    address: "آدرس",
  };

  const handleNumberInputChange = (e, fieldName) => {
    const value = convertToEnglishNumber(e.target.value);
    formik.setFieldValue(fieldName, value);
  };

  return (
    <>
      <Navbar />
      {productloading === (infoloading === priceloading) ? (
        <div className="w-full h-[100vh] flex pt-20 justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="app cbo flex pt-36 px-4">
          <div className="w-[60%] ssni">
            <h1 className="text-3xl mb-4">اطلاعات شخصی برای تکمیل خرید</h1>
            <form
              id={"infoform"}
              onSubmit={formik.handleSubmit}
              className="space-y-4"
            >
              <div className="flex flex-wrap -mx-2">
                {Object.keys(fieldLabels).map((field) =>
                  field !== "address" ? (
                    <div key={field} className="w-1/2 mt-3 px-2">
                      <Input
                        size="lg"
                        label={fieldLabels[field]}
                        variant="bordered"
                        id={field}
                        type="text"
                        pattern={
                          field === "postcode" || field === "phoneNumber"
                            ? "\\d*"
                            : undefined
                        }
                        isInvalid={
                          formik.touched[field] && formik.errors[field]
                            ? true
                            : false
                        }
                        className={`w-full ${
                          formik.touched[field] && formik.errors[field]
                            ? "error"
                            : ""
                        }`}
                        labelProps={{ className: "zero" }}
                        value={formik.values[field]}
                        onChange={(e) => handleNumberInputChange(e, field)}
                      />
                      {formik.touched[field] && formik.errors[field] && (
                        <div className="ml-0 text-red-500 mt-2 pr-1">
                          {formik.errors[field]}
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              </div>
              <div className="mt-4">
                <Textarea
                  size="lg"
                  rows={10}
                  placeholder="آدرس"
                  variant="bordered"
                  id="address"
                  isInvalid={
                    formik.touched["address"] && formik.errors["address"]
                      ? true
                      : false
                  }
                  className={`[&>*:first-child]:[&>*:first-child]:[&>*:first-child]:min-h-[250px] ${
                    formik.touched["address"] && formik.errors["address"]
                      ? "error"
                      : ""
                  }`}
                  labelProps={{ className: "zero" }}
                  value={formik.values["address"]}
                  onChange={(e) =>
                    formik.setFieldValue("address", e.target.value)
                  }
                />
                {formik.touched["address"] && formik.errors["address"] && (
                  <div className="ml-0 text-red-500 mt-2 pr-1">
                    {formik.errors["address"]}
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="cbox w-[40%] p-4 pt-3">
            <div className="flex w-full p-4 border-2 mt-[54px] border-[#] rounded-xl ">
              <div className="w-full h-auto">
                <h1 className="text-xl mb-4 text-center border-b-2 pb-5">
                  {" "}
                  تکمیل خرید
                </h1>
                <div className="text-gray-500 border-b-2 pb-3 text-lg">
                  محصولات:
                  {product?.map((item) => (
                    <span
                      className="text-gray-600"
                      dir="rtl"
                      key={item.count}
                    >{` ${item.name} × ${formatNumberWithCommas(
                      item.count.toLocaleString("fa")
                    )}،`}</span>
                  ))}
                </div>
                <div className="flex  items-center border-b-2 py-2">
                  <h1 className="text-gray-500">حمل و نقل:</h1>
                  <span className="w-[60%] cin text-gray-600 ml-0">
                    حمل و نقل با توجه به آدرس انتخابی شما و کد پستی با پست برای
                    شما ارسال میگردد
                  </span>
                </div>
                <div className="py-5 border-b-2 justify-between flex">
                  <h1 className="text-gray-500 text-2xl zero">قیمت نهایی:</h1>
                  <span className="w-[60%] pl-5 text-end text-3xl zero text-gray-800">
                    {price === undefined ? (
                      <Spinner />
                    ) : (
                      <span>
                        {`${formatNumberWithCommas(
                          price?.toLocaleString("fa")
                        )} تومان`}
                      </span>
                    )}
                  </span>
                </div>
                <div className="py-3 border-b-2 ">
                  خرید از درگاه امن پرداخت اینترنتی زرین پال
                </div>
                <h1 className="mt-3">
                  <Checkbox
                    className="ml-1"
                    onChange={(e) => setRule(!e.target.checked)}
                  ></Checkbox>
                  من{" "}
                  <button onClick={onOpen} className="text-blue-400">
                    شرایط و مقررات
                  </button>{" "}
                  سایت را خوانده ام و آن را می پذیرم.
                </h1>
                <div>
                  <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                    <ModalContent>
                      {(onClose) => (
                        <>
                          <ModalHeader className="flex flex-col gap-1">
                            شرایط و مقررات
                          </ModalHeader>
                          <ModalBody>
                            <p>
                              با توجه به حقوق مادی و معنوی نویسندگان و ناشران،
                              اینجانب به شدت تأکید می‌نمایم که پس از انجام
                              عملیات خرید، هیچ گونه امکانی برای بازپرداخت وجود
                              نخواهد داشت. این تصمیم، به منظور حمایت کامل از
                              آفرینش‌های فکری و زحماتی که توسط نویسندگان و
                              ناشران به عمل آمده است، اتخاذ گردیده است. باید
                              توجه داشت که هر محصولی ممکن است دریافت آن مواجه با
                              خطاهایی یا مشکلاتی ناشی از ارتباط باشد. لذا، در
                              صورتی که هرگونه نقص یا مشکلی در فرآیند دریافت
                              محصول تجربه کردید، خواهشمندیم فوراً با تیم
                              پشتیبانی ما تماس حاصل فرمایید. شماره تماس
                              پشتیبانی: (۰۹۱۱۳۳۰۴۰۷۹). از همکاری و انتخاب شما
                              سپاسگزاریم و اعتماد شما را به عهده می‌گیریم که
                              برای ما بسیار ارزشمند است.
                            </p>
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onPress={onClose}>
                              تایید
                            </Button>
                          </ModalFooter>
                        </>
                      )}
                    </ModalContent>
                  </Modal>
                </div>

                <Button
                  className="mt-10 w-full"
                  isDisabled={rule}
                  size="lg"
                  color="primary"
                  form="infoform"
                  type="submit"
                >
                  {buttonloading ? (
                    <>
                      <div>
                        <Spinner className="mt-2" size="sm" color="default" />
                      </div>
                    </>
                  ) : (
                    "تایید و پرداخت"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-10">
        <Footer />
      </div>
    </>
  );
};

export default Checkout;
