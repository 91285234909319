import React, { useState, useEffect, useRef, useCallback } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Searchbar = (props) => {
    const navigate = useNavigate();
    const [data, setdata] = useState("");
    const debounceTimeoutRef = useRef(null);
    const lastSubmittedValueRef = useRef(null);

    useEffect(() => {
        setdata(props.value || "");
    }, [props.value]);

    const debounceNavigate = useCallback((value) => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = setTimeout(() => {
            navigate(`/search/${value}`);
        }, 300); // delay of 300ms
    }, [navigate]);

    const submithandler = (e) => {
        e.preventDefault();
        if (data !== "" && data !== lastSubmittedValueRef.current) {
            debounceNavigate(data);
            lastSubmittedValueRef.current = data;
        }
    };

    return (
        <>
            <form
                onSubmit={submithandler}
                className="searchbar overflow-hidden bg-[#EDEDEE] items-center flex justify-between w-[600px] p-3 rounded-[25px]"
                action=""
                aria-label="Search Form"
            >
                <input
                    value={data}
                    onChange={(e) => {
                        setdata(e.target.value);
                    }}
                    placeholder="دنبال چه چیزی می‌گردی؟"
                    type="text"
                    className="flex-grow searchput focus:outline-none pr-3 bg-[#EDEDEE]"
                    aria-label="Search Input"
                />
                <button type="submit" aria-label="Search Button">
                    <CiSearch type="submit" className="zero searchicon text-[25px]" />
                </button>
            </form>
        </>
    );
};

export default Searchbar;