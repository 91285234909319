import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import moment from "jalali-moment";
import Orderprod from "./Orderprod";
import { Spinner } from "@nextui-org/react";

import { Link } from "react-router-dom";
import notfoundcover from "../assets/notfound.avif";
import { Button } from "@nextui-org/react";

const Orders = () => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    const response = await fetch("/api/cart/list_orders", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
      }),
    });
    const result = await response.json();
    setdata(result);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  function transformObject(inputObject) {
    const resultArray = [];

    for (const key in inputObject) {
      if (Object.hasOwnProperty.call(inputObject, key)) {
        const name = key;
        const count = inputObject[key];
        resultArray.push({ name, count });
      }
    }

    return resultArray;
  }

  const convertToPersianDate = (serverDateString) => {
    const serverDate = new Date(serverDateString);
    const jalaliDate = moment(serverDate).locale("fa").format("jD jMMMM jYYYY");
    const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const persianDateWithPersianDigits = jalaliDate.replace(
      /\d/g,
      (digit) => persianDigits[digit]
    );

    return persianDateWithPersianDigits;
  };

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="w-full h-[100vh] flex justify-center items-center">
          <Spinner className="pt-[140px]" />
        </div>
      ) : data.length === 0 ? (
        <>
          <div className="w-full h-[100vh] flex flex-col justify-center items-center">
            <img className="w-[600px]" src={notfoundcover} alt="Not Found" />
            <h1 className="text-4xl text-[#c9455d] font-bold">
              متاسفانه سفارش های شما خالی است!!!
            </h1>
            <Button className="bg-[#E14154] p-0 mt-7 text-white">
              <Link className="w-full flex items-center p-4 h-full" to={"/"}>
                برگشت به صفحه اصلی
              </Link>
            </Button>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="app pt-[140px] ">
            <div className="flex w-full flex-col">
              {data &&
                data.map((order) => (
                  <div
                    key={`${order.order_id}`}
                    className="w-full mb-10 flex flex-wrap bg-[white] shadow-xl  rounded-xl p-6"
                  >
                    <h1 className="w-full text-2xl">
                      {`سفارش های ${convertToPersianDate(order.date)} با کد ${
                        order.order_id
                      }`}
                    </h1>

                    {order.products &&
                      transformObject(order.products).map((product) => (
                        <Orderprod order={order} product={product} />
                      ))}
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-[10vh]">
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
