import React from "react";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import Footer from "./Components/Footer";
import Sliderscon from "./Components/Sliderscon";

const App = () => {
  return (
    <>
      <Navbar />
      <div className="app">
        <Hero />
        <Sliderscon />
      </div>
      <Footer />
    </>
  );
};

export default App;
