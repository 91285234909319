import React, { useEffect, useState } from "react";

import { Tooltip } from "@nextui-org/react";
import { FaRegCopy } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";

const Orderprod = (props) => {
  const [data, setData] = useState();
  const [blobUrl, setBlobUrl] = useState(null);
  const fetchData = async () => {
    const response = await fetch("/api/posts/get", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: props.product.name,
      }),
    });
    if (response.ok) {
      const result = await response.json();
      setData(result);
      const blob = new Blob([new Uint8Array(result.image)], {
        type: "image/avif",
      });
      const url = URL.createObjectURL(blob);
      setBlobUrl(url);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="bg-[#e5e5e6]  mt-5 flex rounded-[10px] mx-1 restext w-[49%] ">
      <img
        src={blobUrl}
        className="zero rounded-[10px] oimg h-[150px]"
        alt=""
      />
      <div className="flex-grow flo relative pr-3 justify-center flex flex-col">
        <h2 className="mb-2 small:text-xl  text-2xl font-medium	text-right	">
          {props.product.name}
        </h2>
        <h4 className="text-gray-600 small:text-[13px] text-right ">
          {data?.author}
          {props.order.stage.includes("درحال پست") ? (
            <p className="text-gray-600  small:text-[12px] text-[15px] mt-1 text-right ">
              کد رهگیری:
              <Tooltip showArrow placement="left" content="برای کپی گلیک کنید">
                <button
                  onClick={() =>
                    navigator.clipboard.writeText(
                      props.order.stage.split("-")[1]
                    )
                  }
                  className=" text-gray-500 pt-1 px-1 mr-1 inline-block rounded-lg bg-gray-300 absolute overflow-ellipsis "
                >
                  <span className="flex">
                    <FaRegCopy className="mx-1 mt-[1px]" />
                    {props.order.stage.split("-")[1]}
                  </span>
                </button>
              </Tooltip>
            </p>
          ) : null}
        </h4>
      </div>
      <div className="flex btno items-center">
        <div
          className={`text-white p-2 rounded m-3 ${
            props.order.stage == "تحویل شده" ? "bg-[#3ec966]" : "bg-[#eba94c]"
          }`}
        >
          {data?.type != "کتاب فیزیکی" ? (
            <button
              onClick={async () => {
                window.location.href = `/api/cart/download_post?username=${localStorage.getItem(
                  "username"
                )}&token=${localStorage.getItem("token")}&name=${
                  props.product.name
                }`;
              }}
              className=" w-full h-full text-center"
            >
              دانلود کتاب <MdOutlineFileDownload />
            </button>
          ) : (
            <h4 className="">{props.order.stage.split("-")[0]}</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orderprod;
