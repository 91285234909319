import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { NextUIProvider } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { Spinner } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Notfound from "./Notfound.jsx";

const Product = () => {
  String.prototype.replacePlusWithSpace = function () {
    return this.replace(/\+/g, " ");
  };
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const modifiedid = id.replacePlusWithSpace();
  const [isfound, setfound] = useState(true);
  const [blobUrl, setBlobUrl] = useState(null);
  const [isloadding, setisldoading] = useState(false);
  const [disable, setdisable] = useState(false);
  const navigate = useNavigate();
  String.prototype.replacePlusWithSpace = function () {
    return this.replace(/\+/g, " ");
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/posts/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: modifiedid,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setdata(result);
        setLoading(false);
        const blob = new Blob([new Uint8Array(result.image)], {
          type: "image/avif",
        });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
        return () => URL.revokeObjectURL(url);
      } else if (response.status === 404) {
        setfound(false);
      }
    };

    fetchData();
  }, [id]);

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const clickhandler = async () => {
    const username = localStorage.getItem("username");
    const token = localStorage.getItem("token");
    if (token === "null" && username === "null") {
      navigate("/login");
    } else {
      setisldoading(true);

      const response = await fetch("/api/cart/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_data: {
            username,
            token,
          },
          name: data.name,
          count: 1,
        }),
      });

      if (response.status >= 200 && response.status <= 299) {
        successnotif("محصول به سبد خرید اضافه شد");
      } else if (response.status == 401 || username === null) {
        navigate("/login");
      } else if (response.status == 507) {
        errornotif("سقف این محصول در سبد خرید به حداکثر رسیده");
        setdisable(true);
      } else {
        errornotif("مشکلی پیش آمد بعدا تلاش کنید");
      }

      setisldoading(false);
    }
  };

  const successnotif = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  const errornotif = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_LEFT,
      rtl: true,
      autoClose: 2000,
    });
  };

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Spinner />
      </div>
    );
  }
  String.prototype.replaceSpacesWithPlus = function () {
    return this.replace(/ /g, "+");
  };

  return (
    <>
      {isfound ? (
        <>
          <Navbar />
          <NextUIProvider>
            <div className="app pt-[140px] ">
              <ToastContainer className={"index"} />
              <div className="w-full flex respro justify-between  ">
                <div className="flex bxl zero">
                  <img
                    src={blobUrl}
                    className="zero shadow-2xl procover h-[400px] w-[300px] rounded-3xl"
                    alt="عکس کتاب"
                  />
                  <div className="zero slrtl p-3 pr-5  relative">
                    <h1 className="text-3xl text-[#3B3B3D]">{data?.name}</h1>
                    <ul className="p-0 mt-10">
                      <li className="mt-3">
                        {" "}
                        <span className="text-[#969696]"> انتشارات: </span>{" "}
                        <span className="text-[#666]"> سپیدقلم</span>
                      </li>
                      <li className="mt-3">
                        {" "}
                        <span className="text-[#969696]"> نویسنده: </span>{" "}
                        <span className="text-[#666]"> {data?.author} </span>
                      </li>
                      <li className="mt-3">
                        {" "}
                        <span className="text-[#969696]">
                          {" "}
                          دسته بندی:{" "}
                        </span>{" "}
                        <span className="text-[#666]">
                          <Link
                            to={`/products/${data?.slider_tag.replaceSpacesWithPlus()}`}
                          >
                            {data?.slider_tag}
                          </Link>
                        </span>
                      </li>
                      <li className="mt-3">
                        {" "}
                        <span className="text-[#969696]"> امتیاز: </span>{" "}
                        <span className="text-[#666]"> {data?.score}</span>
                      </li>
                    </ul>

                    <div className="absolute bottom-2 p-2  items-center text-sm bg-[#919c9c] text-[#ffffff] rounded-2xl">
                      {data?.type}
                    </div>
                  </div>
                </div>
                <div className="productadd w-[400px] px-6 py-4 zero flex flex-col h-auto bg-white rounded-2xl shadow-2xl">
                  <h2 className="text-2xl border-b-2 p-7 text-center text-[#717174]">
                    {data?.name}
                  </h2>
                  <h3 className="p-3 py-4 border-b-2">ضمانت سلامت کامل کالا</h3>
                  <h3 className="p-3 py-4 border-b-2">ارسال با پست</h3>

                  {data.discounted_price !== null ? (
                    <>
                      <div className="w-full justify-between flex-grow flex items-center">
                        <h4 className="text-2xl flex items-center">
                          <span className="text-gray-500 text-[20px]">
                            قیمت بدون تخفیف:
                          </span>
                        </h4>
                        <span className="text-lg zero line-through text-gray-500 ">
                          {`${formatNumberWithCommas(
                            data?.price.toLocaleString("fa")
                          )} تومان`}
                        </span>
                      </div>
                      <div className="w-full justify-between flex-grow flex items-center mt-1">
                        <h4 className="text-2xl flex items-center">
                          <span className="text-black text-[20px]">
                            قیمت با تخفیف:
                          </span>
                        </h4>
                        <span className="text-lg zero text-[#0abd1f]">
                          {`${formatNumberWithCommas(
                            data?.discounted_price.toLocaleString("fa")
                          )} تومان`}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="w-full justify-between flex-grow flex items-center mt-1">
                      <h4 className="text-2xl flex items-center">
                        <span className="text-black text-[30px]">قیمت :</span>
                      </h4>
                      <span className="text-lg zero text-[#5d5f5d]">
                        {`${formatNumberWithCommas(
                          data?.price.toLocaleString("fa")
                        )} تومان`}
                      </span>
                    </div>
                  )}
                  <Button
                    onClick={clickhandler}
                    isDisabled={disable}
                    className="bg-[#0abdba] text-md text-white mt-4"
                  >
                    {isloadding ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      "افزودن به سبد خرید"
                    )}
                  </Button>
                </div>
              </div>
              <div className=" respro">
                <h2 className="mt-20 text-3xl text">{data?.title}</h2>
                <p className="my-10 leading-7 break-words text-lg">
                  {data?.description}
                </p>
              </div>
            </div>
          </NextUIProvider>
          <Footer />
        </>
      ) : (
        <Notfound />
      )}
    </>
  );
};
export default Product;
