import React from "react";
import Logo from "../assets/logo.avif";
import Searchbar from "./Searchbar";
import Acc from "./Acc";
import { FiHeadphones } from "react-icons/fi";
import { FaBookAtlas } from "react-icons/fa6";
import { GoBook } from "react-icons/go";
import { BsFeather } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HiOutlineHome } from "react-icons/hi";
import Sidebar from "./Sidebar";

const Navbar = (props) => {
  return (
    <div className="index fixed navbar zero w-full pt-3 pb-[6px] bg-[#fffefe] shadow-md">
      <div className="app flex items-center justify-between">
        <div className="zero logonone">
          <Link to="/" aria-label="Home">
            <img src={Logo} alt="صفحه اصلی" />
          </Link>
        </div>
        <div className="zero flex bar justify-center">
          <Searchbar value={props.value} />
        </div>
        <div className="zero flex  items-center">
          <Acc />
          <Sidebar />
        </div>
      </div>
      <div className="app side-none sc pt-1 pr-1  flex ">
        <div className="zero">
          <div className="text-[17px] ml-5 text-[#47565C] inline-block">
            <Link className="flex items-center" to="/" aria-label="Home">
              <HiOutlineHome className="ml-1 text-[17px]" /> صفحه اصلی
            </Link>
          </div>
          <div className="text-[17px] ml-5 text-[#47565C] inline-block">
            <Link
              className="flex items-center"
              to="/abook"
              aria-label="Audio Books"
            >
              <FiHeadphones className="ml-1 text-[17px]" /> کتاب صوتی
            </Link>
          </div>
          <div className="text-[17px] ml-5 text-[#47565C] inline-block">
            <Link
              className="flex items-center"
              to="/ebook"
              aria-label="E-Books"
            >
              <FaBookAtlas className="ml-1 text-[17px]" /> کتاب الکترونیکی
            </Link>
          </div>
          <div className="text-[17px] ml-5 text-[#47565C] inline-block">
            <Link
              className="flex items-center"
              to="/book"
              aria-label="Physical Books"
            >
              <GoBook className="ml-1 text-[17px]" /> کتاب فیزیکی
            </Link>
          </div>
          <div className="text-[17px] ml-5 text-[#47565C] inline-block">
            <Link
              className="flex items-center"
              to="/about"
              aria-label="About SepidQalam"
            >
              <BsFeather className="ml-1 text-[17px]" /> درباره سپیدقلم
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
