import React, { useState, useEffect, useRef } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { VscAdd } from "react-icons/vsc";
import { FaMinus } from "react-icons/fa6";
import { Spinner } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
} from "@nextui-org/react";

const Cart = (props) => {
  const [loading, setLoading] = useState(false);
  const [count, setcount] = useState(props.count);
  const [data, setdata] = useState();
  const [countloading, setcountloading] = useState(false);
  const [trashloading, settrashloading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const none = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetch("/api/posts/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: props.name,
        }),
      });
      if (response.ok) {
        setdata(await response.json());
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  function formatNumberWithCommas(number) {
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const addhandler = async () => {
    setcountloading(true);
    const response = await fetch("/api/cart/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        name: data.name,
        count: 1,
      }),
    });

    if (response.ok) {
      setcount(count + 1);
      props.fnc(1, data.discounted_price, data.price);
    }
    setcountloading(false);
  };

  const Deletehandler = async () => {
    settrashloading(true);
    const response = await fetch("/api/cart/delete_all", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        name: data.name,
      }),
    });
    if (response.ok) {
      if (none.current) {
        none.current.classList.add("none");
        props.succ("محصول با موفقیت حذف شد");
      }
      props.fnc(-count, -data.discounted_price * count, -data.price * count);
    } else if (response.status == 401) {
      localStorage.setItem("token", null);
      localStorage.setItem("username", null);
      navigate("/login");
    }
    settrashloading(false);
    onClose();
  };

  const delhandler = async () => {
    setcountloading(true);
    const response = await fetch("/api/cart/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_data: {
          username: localStorage.getItem("username"),
          token: localStorage.getItem("token"),
        },
        name: data.name,
      }),
    });

    if (response.ok) {
      setcount(count - 1);
      props.fnc(-1, -data.discounted_price, -data.price);
      setcountloading(false);
    }
  };

  const getImageBlob = () => {
    if (data?.image) {
      const byteArray = new Uint8Array(data.image);
      return new Blob([byteArray], { type: "image/jpeg" });
    }
    return null;
  };

  return (
    <div
      ref={none}
      className="mt-3 flex ydy w-full min-h-[250px] relative bg-white shadow-md rounded-xl"
    >
      {loading ? (
        <div className="w-full h-auto flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="zero flex pt-3 items-start ">
            <img
              className="rounded-xl mr-3 w-[150px]"
              src={getImageBlob() ? URL.createObjectURL(getImageBlob()) : ""}
              alt="عکس محصول"
            />
          </div>

          <div className="flex-grow poss relative py-7 px-6 h-auto">
            <div className="w-full adsil relative items-center flex justify-between">
              <h4 className="text-gray-600 text-xl">{data?.name}</h4>
              <Button
                isIconOnly
                onClick={onOpen}
                className="absolute tras transition-all focus:scale-95 hover:bg-red-500 hover:text-white rounded-full left-0 zero p-3 text-red-700 bg-[#fceded]"
              >
                <FaRegTrashAlt />
              </Button>
              <Modal backdrop="opaque" isOpen={isOpen}>
                <ModalContent className="w-auto dldldl">
                  <ModalBody className="zero inline-block">
                    <h3 className="mt-3 mb-6">
                      آیا از حذف این محصول اطمینان دارید؟
                    </h3>
                  </ModalBody>
                  <ModalFooter className="pt-1  pb-2 ">
                    <Button onPress={onClose} color="primary" variant="light">
                      بستن
                    </Button>
                    <Button
                      onClick={Deletehandler}
                      color="danger"
                      type="submit"
                    >
                      {trashloading ? (
                        <Spinner size="sm" color="default" />
                      ) : (
                        <span>حذف محصول</span>
                      )}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
            <h5 className="mt-1 text-gray-500">{data?.author}</h5>

            <div className="flex absolute mt-3 flex-col ">
              <h5 className=" px-2 py-1 text-xs bg-gray-200 text-gray-600 rounded-full">
                {data?.type}
              </h5>
            </div>
            <div
              className={`zero ${
                data?.type != "کتاب فیزیکی" ? "none" : ""
              } flex fla w-[170px] justify-between absolute bottom-5`}
            >
              <Button
                onClick={addhandler}
                isDisabled={count === 5 || countloading}
                isIconOnly
                className="zero cursor-pointer p-2 rounded-full bg-[#f0fff8] text-green-500 flex justify-center items-center"
              >
                <VscAdd className="mt-[3px]" />
              </Button>
              <div className="zero flex justify-center items-center text-2xl ">
                {countloading ? (
                  <Spinner size="sm" color="default" />
                ) : (
                  count.toLocaleString("fa")
                )}
              </div>
              <Button
                onClick={delhandler}
                isDisabled={count === 1 || countloading}
                isIconOnly
                className="zero cursor-pointer flex text-red-500 rounded-full p-2 bg-[#fceded] justify-center items-center"
              >
                <FaMinus />
              </Button>
            </div>

            <div className="zero abl absolute left-7  bottom-5">
              {data?.discounted_price ? (
                <>
                  <div className="text-sm zero  text-gray-700">
                    قیمت بدون تخفیف:{" "}
                    <span className="text-lg mr-2 text-gray-500 line-through">{`${formatNumberWithCommas(
                      (data?.price * count).toLocaleString("fa")
                    )} تومان`}</span>
                  </div>
                  <div dir="ltr" className="text-sm  text-gray-700">
                    قیمت با تخفیف:{" "}
                    <span className="text-lg mr-2 text-green-500">{`${formatNumberWithCommas(
                      (data?.discounted_price * count).toLocaleString("fa")
                    )} تومان`}</span>
                  </div>
                </>
              ) : (
                <div dir="ltr" className="text-sm  text-gray-700">
                  قیمت :{" "}
                  <span className="text-lg mr-2 text-gray-700">{`${formatNumberWithCommas(
                    (data?.price * count).toLocaleString("fa")
                  )} تومان`}</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
