import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import library from "../assets/library.avif";
import semi from "../assets/semi.avif";
import blibrary from "../assets/blibrary.avif";

const About = () => {
  return (
    <div className="About">
      <Navbar />
      <div className="app pt-[120px]">
        <div className="flex hlcon w-full ">
          <div className="w-[55%] hlc zero">
            <h1 className="mt-20 headb font-bold text-4xl text-[#58bd94]">
              خوش آمدید به وبسایت رسمی انتشارات سپید قلم!
            </h1>
            <p className="mt-10 textb text-3xl text-[#3b725b]">
              از فروردین سال ۱۳۹۸ ما یک انتشارات پویا و پیشرو هستیم که به هدف
              ارتقا فرهنگ کتابخوانی و انتشار آثار مفید و کیفی در دسته‌های مختلف
              متمرکز شده‌ایم. با تیمی متخصص و با انگیزه. آثاری به انتشارات ما
              راه یافته‌اند که توانسته‌اند اندیشه‌ها را شکوفا و ذهن‌ها را
              برانگیزانند.
            </p>
          </div>
          <div className="w-[45%] mgc h-auto flex flex-col justify-center zero">
            <img src={library} className="w-full" alt="عکس کتابخانه" />
          </div>
        </div>
        <div className="flex hlcon hpf mt-20 w-full ">
          <div className="w-[45%] zero mgc flex-col flex justify-center ">
            <img src={blibrary} className="w-full" alt="عکس کتابخانه" />
          </div>
          <div className="w-[55%] hlc  pr-5 zero">
            <h1 className="mt-10 headb font-bold text-4xl text-[#097FCE]">
              کیفیت، تنوع، و رضایت سلیقه خوانندگان
            </h1>
            <p className="mt-10 textb mb-5 text-3xl text-[#3b90bb]">
              در انتشارات ما، ارزش قرار دادن بر روی کیفیت، تنوع و ارتقاء سلیقه
              خوانندگان از اهداف اصلی ماست. ما افتخار می‌کنیم که محیطی پویا و
              دوستانه را برای نویسندگان و خوانندگانمان فراهم آورده‌ایم و به
              عنوان پل ارتباطی بین آنها عمل می‌کنیم
            </p>
          </div>
        </div>
        <div className="mt-20 hlcon  flex w-full">
          <div className="zero hlc pr-5 w-[55%]">
            <h1 className="mt-20 headb font-bold text-4xl text-[#55a394]">
              حمایت و انتشار آثار نویسندگان نوپا
            </h1>
            <p className="mt-10 textb text-3xl text-[#55a394]">
              ما به دنبال کشف و حمایت از استعدادهای نوپا در دنیای نویسندگی هستیم
              و از آنجاییکه باور داریم کتاب یکی از بهترین ابزارها برای انتقال
              اندیشه‌ها و دانش است، بر آنیم تا نویسندگانی که دارای دید نوین و
              محتوای جذاب هستند را شناسایی کرده و آثارشان را منتشر کنیم.
            </p>
          </div>
          <div className="zero mgc flex flex-col justify-center  w-[45%]">
            <img src={semi} className="w-full" alt="عکس کتاب" />
          </div>
        </div>
        <h2 className="my-20 mb-10 w-full text-center text-3xl">
          انتشارات سپید قلم به مدیریت دکتر منصوره دل آور اکنون با انتشار بیش از
          ۳۰۰ اثر دراختیار شماست.
        </h2>
      </div>

      <Footer />
    </div>
  );
};

export default About;
