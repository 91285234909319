import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@nextui-org/react";
import { useParams } from "react-router-dom";

const Resetpass = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [pageloading, setPageloading] = useState(true);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
    server: "",
  });

  useEffect(() => {
    const validation = async () => {
      const response = await fetch(
        "/api/users/is_reset_password_verification_token_valid",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            verification_token: id,
          }),
        }
      );
      if (response.ok) {
        setPageloading(false);
      } else {
        navigate("/notfound");
      }
    };
    validation();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({ ...errors, server: "" });

    const newErrors = {};
    if (!formData.password) {
      newErrors.password = "رمز عبور نمی‌تواند خالی باشد";
    } else if (formData.password.length < 6) {
      newErrors.password = "رمز عبور باید حداقل 6 کاراکتر باشد";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "رمزهای عبور مطابقت ندارند";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    const response = await fetch("/api/users/verify_reset_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        verification_token: id,
        password: formData.password,
      }),
    });
    if (response.ok) {
      const res = await response.json();

      localStorage.setItem("token", res.token);
      localStorage.setItem("username", res.username);
      navigate("/");
    }

    setIsLoading(false);
  };

  const navigate = useNavigate();
  return pageloading ? (
    <div className="w-full h-[100vh] flex ">
      <Spinner />
    </div>
  ) : (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-[40px] py-[50px] rounded-[30px] shadow-md w-96">
        <h2 className="text-2xl font-semibold flex justify-center mb-[80px]">
          تغییر رمز عبور
        </h2>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              htmlFor="password"
              className="block mb-2 mr-2 text-sm font-medium text-gray-600"
            >
              رمزعبور جدید:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`mt-1 p-2 pr-3 bg-[#EDEDEE] w-full rounded-[20px] focus:outline-none ${
                errors.password ? "border border-red-500" : ""
              }`}
            />
            {errors.password && (
              <div className="text-red-500">{errors.password}</div>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="confirmPassword"
              className="block mb-2 mr-2 text-sm font-medium text-gray-600"
            >
              تایید رمزعبور:
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              className={`mt-1 p-2 pr-3 bg-[#EDEDEE] w-full rounded-[20px] focus:outline-none ${
                errors.confirmPassword ? "border border-red-500" : ""
              }`}
            />
            {errors.confirmPassword && (
              <div className="text-red-500">{errors.confirmPassword}</div>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600 focus:outline-none"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "تغییر رمز عبور"}
          </button>
        </form>
        {errors.server && (
          <div className="text-red-500 mt-3">{errors.server}</div>
        )}
      </div>
    </div>
  );
};

export default Resetpass;
